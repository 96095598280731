import React, { Component } from 'react';
/* eslint-disable  */
import { Col, Container, Row, CardBody, Table } from 'reactstrap';
import MetaTags from 'react-meta-tags';

import axios from 'axios';
import { MY_USER_URL } from "../../../config/QueryMe";
import Switch from 'react-switch';
const initialstate = {
  itempageabout: 'Users',
  tname: 'u',
  tdepname: 'dummy',
  allitems: [],

};
class CUsermng extends Component {
  state = { ...initialstate };

  constructor(props) {
    super(props)
    // this.state = {}
    this.handleGetkeys = this.handleGetkeys.bind(this);
    this.handleEditkey = this.handleEditkey.bind(this);
  }

  componentDidMount() {
    this.handleGetkeys();
  }

  handleGetkeys = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      uid: localStorage.usercode,
      table: this.state.tname,
      deptable: this.state.tdepname,
    };
    const getuurl = MY_USER_URL + 'getusers';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (!response.data.error) {
          localStorage.setItem("token", response.data.token);
          const allitems = [...response.data.allitems];
          console.log('allitems', response.data.allitems);

          this.setState({
            allitems: allitems,
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        // localStorage.clear();
        //this.props.history.push('/'); //eslint-disable-line
      });
  };
  handleEditkey = (id, item, role, name, mobile, password, checked) => {
    console.log(checked + 'checked');
    console.log(id + 'id');
    console.log(item + 'item');
    console.log(role + 'role');
    console.log(name + 'name');
    console.log(mobile + 'mobile');
    this.setState({
      addKeyModal: true,
      editmode: true,
      editid: id,
      itemvalue: item,
      userrole: role,
      name: name,
      mobile: mobile,
      password: password,
      itemactionname: 'Edit ' + this.state.itemsubonename,
    });
  };
  handleTkey = (id, item, checked) => {
    console.log(checked + ' checked');
    console.log(id + ' id');
    console.log(item + ' item');
    var keysts = checked == true ? 0 : 1;
    var kstatus = 'Deactivate';
    if (checked) {
      kstatus = 'Activate';
    }
    swal({
      title: kstatus + ' ' + item,
      text: 'Are you sure?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((keyconfirm) => {
      if (keyconfirm) {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: localStorage.token,
        };

        const togkeyurl = MY_USER_URL + 'toguser';
        var postData = {
          userid: id,
          ustatus: keysts,
          uid: localStorage.usercode,
          table: this.state.tname,

        };
        axios
          .post(togkeyurl, postData, {
            headers: headers,
          })
          .then((response) => {
            console.log(response.data.message);

            if (response.data.error) {
              swal(item + ' ' + response.data.message, {
                icon: 'warning',
              });
            } else {
              localStorage.setItem("token", response.data.token);
              this.handleGetkeys();
              swal(item + ' ' + response.data.message, {
                icon: 'success',
              });
            }
          })
          .catch((error) => {
            console.log('Error is ', error);
            swal('Try Again Later', {
              icon: 'Danger',
            });
          });
      }
    });
  };
  render() {
    const { allitems } = this.state;
    return (
      <Container className="dashboard">
        <MetaTags>
          <title>{this.state.itempageabout} </title>
        </MetaTags>
        <Row>
          <Col md={12}>
            <h5 className="page-title">{this.state.itempageabout}</h5>
          </Col>
        </Row>
        <Row>
          <Col md={12} style={{ marginBottom: '20px' }}>
            <CardBody style={{ padding: '10px' }}>
              <div className="table-responsive">
                <Table className="table mb-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Role</th>
                      <th>Name</th>
                      <th>{this.state.itempageabout}</th>
                      <th>Mobile</th>
                      <th>Manage</th>
                      <th>date Added</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.allitems &&
                      this.state.allitems.map((item, index) => {
                        var usts = true;
                        if (item.blocked == 1) {
                          usts = false;
                        }

                        return (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>
                              {item.role}
                            </td>
                            <td>
                              {/* <span className="float-start"
                                style={{ marginRight: '10px', cursor: 'pointer' }}
                                onClick={this.handleEditkey.bind(
                                  this,
                                  item.id,
                                  item.email,
                                  item.role,
                                  item.itemname,
                                  item.mobile,
                                )}
                              >
                                <i className="mdi mdi-pencil d-block font-size-16"></i>{" "}{" "}
                              </span> */}
                              <strong>{" "}{item.itemname}</strong>
                            </td>
                            <td>{item.email}</td>
                            <td>{item.mobile}</td>
                            <td>
                              <Switch
                                onChange={this.handleTkey.bind(
                                  this,
                                  item.id,
                                  item.mobile,
                                )}
                                checked={usts}
                              />
                            </td>

                            <td>
                              {item.posted}
                            </td>

                          </tr>
                        );
                      })}

                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default CUsermng;
