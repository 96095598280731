import React, { useState } from 'react';
/* eslint-disable  */
import { Link } from 'react-router-dom';
import { Col, CardBody, Row, Collapse } from 'reactstrap';
import PinWithInfoWindowcust from '../Maps/GoogleMap/components/PinWithInfoWindowcust';
import MinusIcon from 'mdi-react/MinusIcon';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import FileIcon from 'mdi-react/FileIcon';



const ItemCard = ({ item }) => {

  const [showmap, setShowmap] = useState(false);
  const [showdes, setShowdes] = useState(false);
  const togglemap = () => {
    setShowmap(prevState => !prevState);
    setShowdes(false);
  };
  const toggledes = () => {
    setShowmap(false);
    setShowdes(prevState => !prevState);
  };
  const [mapview, setMapview] = useState({ styleClass: 'closed', mapicon: <MapMarkerIcon color="#c2212e" /> });
  const onShowmaping = () => {
    setMapview({ styleClass: 'opening', mapicon: <MinusIcon /> });
  };

  const onShowmaped = () => {
    setMapview({ styleClass: 'opened', mapicon: <MinusIcon /> });
  };

  const onShowmapExiting = () => {
    setMapview({ styleClass: 'closing', mapicon: <MapMarkerIcon /> });
  };

  const onShowmapExited = () => {
    setMapview({ styleClass: 'closed', mapicon: <MapMarkerIcon color="#c2212e" /> });
  };

  const [desview, setDesview] = useState({ styleClass: 'closed', desicon: <FileIcon color="#c2212e" /> });
  const onShowdesing = () => {
    setDesview({ styleClass: 'opening', desicon: <MinusIcon /> });
  };

  const onShowdesed = () => {
    setDesview({ styleClass: 'opened', desicon: <MinusIcon /> });
  };

  const onShowdesExiting = () => {
    setDesview({ styleClass: 'closing', desicon: <FileIcon /> });
  };

  const onShowdesExited = () => {
    setDesview({ styleClass: 'closed', desicon: <FileIcon color="#c2212e" /> });
  };


  const hlat = parseFloat(item.hlat);
  const hlong = parseFloat(item.hlong);
  return (



    <Col md={4} style={{ marginBottom: '20px' }}>
      <CardBody style={{ padding: '10px' }}>
        <Row>
          <Col md={3} style={{ marginBottom: '10px' }}>
            {item.logo ? <img className="profile__activity-avatar" style={{ marginRight: '0px' }} src={item.logo} alt="Logo" /> : ''}
          </Col>
          <Col md={9} style={{ marginBottom: '10px' }}>
            <div className="card__title" style={{ marginTop: '0px', marginBottom: '15px' }}>
              <h5 className="bold-text">{item.title}</h5>
              <h5 className="subhead">
                {item.itemtype}
                <span style={{ float: 'right' }}>{item.timings}</span></h5>
            </div>
          </Col>
        </Row>
        <div className="catalog-item__img-wrap">
          <img src={item.building} alt="Building" />
        </div>


        <div className="profile__stats">
          <div className="profile__stat" onClick={toggledes}>
            <p className="profile__stat-number">{desview.desicon}</p>
            <p className="profile__stat-title">About</p>
          </div>
          <div className="profile__stat">
            <p className="profile__stat-number">{' '}</p>
            <p className="profile__stat-title">{' '}</p>
          </div>
          <div className="profile__stat" onClick={togglemap}>
            <p className="profile__stat-number">{mapview.mapicon}</p>
            <p className="profile__stat-title">Map</p>
          </div>
        </div>
        <Collapse
          isOpen={showmap}
          className="collapse__content"
          onEntering={onShowmaping}
          onEntered={onShowmaped}
          onExiting={onShowmapExiting}
          onExited={onShowmapExited}
        >
          <div>

            <PinWithInfoWindowcust hname={item.title} haddress={item.address} vlat={hlat} vlong={hlong} />
          </div>
        </Collapse>
        <Collapse
          isOpen={showdes}
          className="collapse__content"
          onEntering={onShowdesing}
          onEntered={onShowdesed}
          onExiting={onShowdesExiting}
          onExited={onShowdesExited}
        >
          <div>
            {item.opening_time ? (
              <p className="profile__stat-title" style={{ marginBottom: '5px' }}><b>Opening-Closing: </b>{item.opening_time}-{item.closing_time}</p>
            ) : ''}

            <h5 className="bold-text" style={{ marginBottom: '5px' }}>About Venue</h5>
            {item.banner ? <img src={item.banner} alt="Banner" /> : ''}
            {item.description}
          </div>
        </Collapse>

      </CardBody>
    </Col>


  );
};



ItemCard.defaultProps = {
  items: [],
};

export default ItemCard;
