import React, { Fragment, useState } from 'react';
/* eslint-disable */
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col } from 'reactstrap';
import {
  GoogleMap, useJsApiLoader, Marker, InfoWindow,
} from '@react-google-maps/api';

const containerStyle = {
  height: '360px',
};



const PinWithInfoWindowcust = (props) => {
  console.log('props', props);
  const center = {
    lat: props.vlat,
    lng: props.vlong,
  };
  const { t } = useTranslation('common');
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDl4BwSW8cCIThmdajHqf3do5e0TgcWjEw",
  });

  const [isOpened, setIsOpened] = useState(true);

  const onToggleIsOpen = () => {
    setIsOpened(!isOpened);
  };

  const onLoad = (infoWindow) => {
    console.log('infoWindow: ', infoWindow);
  };

  return (

    <Card>
      <CardBody style={{ padding: '0px' }}>
        <div className="card__title">
          <h5 className="bold-text">{props.haddress}</h5>
        </div>
        {isLoaded ? (
          <GoogleMap
            id="infoWindowMap"
            mapContainerStyle={containerStyle}
            center={center}
            zoom={13}
          >
            <Marker position={center} onClick={onToggleIsOpen}>
              {!isOpened && (
                <InfoWindow
                  onLoad={onLoad}
                  options={{ closeBoxURL: '', enableEventPropagation: true }}
                >
                  <div className="map__marker-label-content">
                    <b>{props.hname}</b><br />
                    {props.haddress}<br />
                  </div>
                </InfoWindow>
              )}
            </Marker>
          </GoogleMap>
        ) : <Fragment />}
      </CardBody>
    </Card>

  );
};

export default PinWithInfoWindowcust;
