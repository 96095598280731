import React, { Component } from 'react';
/* eslint-disable  */
import { Alert, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Modal, Button, Table } from "reactstrap";

import MetaTags from 'react-meta-tags';
import axios from 'axios';
// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"
import { ACA_URL } from '../../../config/QueryMe';
// import SweetAlert from "react-bootstrap-sweetalert"
import Switch from 'react-switch';
import swal from 'sweetalert';
//Import Breadcrumb

const initialstate = {
  itempageabout: 'Countries | Database',
  itemmainname: 'Database',
  itemsubname: 'Countries',
  itemsubonename: 'Country',
  itemactionname: 'Add Country',
  tname: 'dbcoun',
  tdepname: 'dummy',
  itemvalue: '',
  allitems: [],
  relateditems: [],
  showAlert: false,
  alertType: '',
  alertText: '',
  keyId: 0,
  keyName: '',
  oldkeyname: '',
  newkeyname: '',
  noorid: 0,

  addKeyModal: false,
  akeys: 0,
  bkeys: 0,
  tkeys: 0,
};
class DbCountry extends Component {
  constructor(props) {
    super(props)
    // this.state = {}
    this.handleGetkeys = this.handleGetkeys.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleDkey = this.handleDkey.bind(this);
    this.handleTkey = this.handleTkey.bind(this);
    this.handleEditkey = this.handleEditkey.bind(this);

  }
  state = { ...initialstate };
  componentDidMount() {

    this.handleGetkeys();


  }

  handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name + ' ' + value);
    this.setState({ [name]: value });
  };
  handleGetkeys = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      uid: localStorage.usercode,
      table: this.state.tname,
      deptable: this.state.tdepname,
    };
    const getuurl = ACA_URL + 'getitems';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (!response.data.error) {
          localStorage.setItem("token", response.data.token);
          const allitems = [...response.data.allitems];
          console.log('allitems', response.data.allitems);
          let akeys = 0;

          let tkeys = 0;
          allitems.map((dkey) => {
            akeys = akeys + dkey.status;
            tkeys = tkeys + 1;
          });

          this.setState({
            akeys: akeys,
            bkeys: tkeys - akeys,
            tkeys: tkeys,
            allitems: response.data.allitems,
            relateditems: response.data.allitems,
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        localStorage.clear();
        this.props.history.push('/'); //eslint-disable-line
      });
  };

  handleValidSubmit = (event, values) => {
    event.preventDefault();

    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var addkeyurl = ACA_URL + 'additem';
    var postData = {
      item: values.item,
      uid: localStorage.usercode,
      table: this.state.tname,

    };
    if (this.state.editmode) {
      addkeyurl = ACA_URL + 'edititem';
      postData = {
        itemid: this.state.editid,
        item: values.item,
        uid: localStorage.usercode,
        table: this.state.tname,

      };
    }


    axios
      .post(addkeyurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log(response.data.message);
        if (response.data.error) {
          this.setState({
            showAlert: true,
            alertType: 'warning',
            alertText: response.data.message,
          });
        } else {
          localStorage.setItem("token", response.data.token);
          this.handleGetkeys();
          this.setState(
            {
              addKeyModal: false,
            });
          swal(values.item + ' ' + response.data.message, {
            icon: 'success',
          });
        }
      })
      .catch((error) => {
        console.log('Error is ', error);
        this.setState({
          alertType: 'danger',
          showAlert: true,
          alertText: 'Failed Try Again Later!',
        });
      });
  };

  handleDkey = (id, item, checked) => {
    console.log(checked + 'checked');
    console.log(id + 'id');
    console.log(item + 'item');

    swal({
      title: 'Delete ' + item,
      text: 'Are you sure?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((keyconfirm) => {
      if (keyconfirm) {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: localStorage.token,
        };

        const delkeyurl = ACA_URL + 'delitem';
        var postData = {
          keyid: id,
          uid: localStorage.usercode,
          table: this.state.tname,
        };
        axios
          .post(delkeyurl, postData, {
            headers: headers,
          })
          .then((response) => {
            console.log(response.data.message);

            if (response.data.error) {
              swal(item + ' ' + response.data.message, {
                icon: 'warning',
              });
            } else {
              localStorage.setItem("token", response.data.token);
              this.handleGetkeys();
              swal(item + ' ' + response.data.message, {
                icon: 'success',
              });
            }
          })
          .catch((error) => {
            console.log('Error is ', error);
            swal('Try Again Later', {
              icon: 'warning',
            });
          });
      }
    });
  };
  handleTkey = (id, item, checked) => {
    console.log(checked + 'checked');
    console.log(id + 'id');
    console.log(item + 'item');
    var keysts = checked == true ? 1 : 0;
    var kstatus = 'Deactivate';
    if (checked) {
      kstatus = 'Activate';
    }
    swal({
      title: kstatus + ' ' + item,
      text: 'Are you sure?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((keyconfirm) => {
      if (keyconfirm) {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: localStorage.token,
        };

        const togkeyurl = ACA_URL + 'togitem';
        var postData = {
          keyid: id,
          kstatus: keysts,
          uid: localStorage.usercode,
          table: this.state.tname,

        };
        axios
          .post(togkeyurl, postData, {
            headers: headers,
          })
          .then((response) => {
            console.log(response.data.message);

            if (response.data.error) {
              swal(item + ' ' + response.data.message, {
                icon: 'warning',
              });
            } else {
              localStorage.setItem("token", response.data.token);
              this.handleGetkeys();
              swal(item + ' ' + response.data.message, {
                icon: 'success',
              });
            }
          })
          .catch((error) => {
            console.log('Error is ', error);
            swal('Try Again Later', {
              icon: 'Danger',
            });
          });
      }
    });
  };

  handleAkey = () => {
    this.setState({
      addKeyModal: true,
      itemactionname: 'Add ' + this.state.itemsubonename,
      editmode: false,
      itemvalue: '',
    });
  };
  handleCkey = () => {
    this.setState({
      addKeyModal: false,
      editmode: false,
      itemvalue: '',

    });
  };
  handleEditkey = (id, item, checked) => {
    console.log(checked + 'checked');
    console.log(id + 'id');
    console.log(item + 'item');
    this.setState({
      addKeyModal: true,
      editmode: true,
      editid: id,
      itemvalue: item,
      itemactionname: 'Edit ' + this.state.itemsubonename,
    });
  };
  render() {

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.state.itempageabout} </title>
          </MetaTags>
          <div className="container-fluid">

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">{this.state.itemsubname}
                      <Button
                        className="float-end btn-sm"
                        color="success"
                        onClick={this.handleAkey}
                      >
                        +
                      </Button>
                    </CardTitle>
                    <p className="card-title-desc">Some Description</p>

                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>{this.state.itemsubonename}</th>
                            <th>date Added</th>
                            <th colSpan='2'>Manage</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.relateditems &&
                            this.state.relateditems.map((mitem, index) => {
                              var ksts = false;
                              if (mitem.status == 1) {
                                ksts = true;
                              }

                              return (
                                <tr key={index}>
                                  <th scope="row">{index + 1}</th>


                                  <td>
                                    <span className="float-start"
                                      style={{ marginRight: '10px', cursor: 'pointer' }}
                                      onClick={this.handleEditkey.bind(
                                        this,
                                        mitem.id,
                                        mitem.itemname,
                                      )}
                                    >
                                      <i className="mdi mdi-pencil d-block font-size-16"></i>{" "}{" "}
                                    </span>
                                    <strong>{" "}{mitem.itemname}</strong>
                                  </td>
                                  <td>{mitem.posted}</td>
                                  <td>
                                    <Switch
                                      height={15}
                                      width={40}
                                      onChange={this.handleTkey.bind(
                                        this,
                                        mitem.id,
                                        mitem.itemname,
                                      )}
                                      checked={ksts}

                                    />
                                  </td>
                                  <td>

                                    {mitem.del == 0 ? (
                                      <button
                                        className="btn btn-danger btn-sm"
                                        color="primary"
                                        id={mitem.id}
                                        onClick={this.handleDkey.bind(
                                          this,
                                          mitem.id,
                                          mitem.itemname,
                                        )}
                                      >
                                        <i className="mdi mdi-trash-can d-block font-size-16"></i>{" "}
                                      </button>
                                    ) : null}
                                  </td>
                                </tr>
                              );
                            })}

                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          <Modal
            isOpen={this.state.addKeyModal}

            centered={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">{this.state.itemactionname} </h5>
              <button
                type="button"
                onClick={this.handleCkey}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">


              <AvForm
                className="needs-validation"
                onValidSubmit={this.handleValidSubmit}
              >
                {this.state.showAlert ? (
                  <Alert color={this.state.alertType}>
                    <p>{this.state.alertText}</p>
                  </Alert>
                ) : null}


                <div className="mb-3">
                  <AvField
                    name="item"
                    label={this.state.itemsubonename}
                    value={this.state.itemvalue}
                    type="text"
                    required
                    placeholder={'Enter ' + this.state.itemsubonename}
                  />
                </div>
                <div className="mt-4 d-grid">
                  <button
                    className="btn btn-primary btn-block waves-effect waves-light"
                    type="submit"
                  >
                    {this.state.itemactionname}
                  </button>
                </div>


              </AvForm>
            </div>
          </Modal>
        </div>
      </React.Fragment>
    )
  }
}

export default DbCountry
