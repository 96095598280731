import React, { Component } from 'react';
/* eslint-disable  */
import { Col, Container, Row } from 'reactstrap';
import MetaTags from 'react-meta-tags';
import UsersclubList from './components/UsersclubList';

const initialstate = {
  itempageabout: 'Club Users Management',

};
class Usersclub extends Component {

  constructor(props) {
    super(props)
    // this.state = {}

  }
  state = { ...initialstate };


  render() {
    return (
      <Container className="dashboard">
        <MetaTags>
          <title>{this.state.itempageabout} </title>
        </MetaTags>
        <Row>
          <UsersclubList />
        </Row>
      </Container>
    );
  }
}
export default Usersclub;
