import React from 'react';
/* eslint-disable  */
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Layout from './Layout/index';
import LayoutAdmin from './LayoutAdmin/index';

const UserPanel = ({ upanel, userrole }) => {
    var userPanel;
    console.log('upanel', upanel);
    if (upanel === 'clubmanager-panel') {
        userPanel = <Layout />;
    } else if (upanel === 'customer-panel') {
        // userPanel = <Layout />;
    }
    return <div>{userPanel}</div>;
};

UserPanel.propTypes = {
    upanel: PropTypes.string.isRequired,
    userrole: PropTypes.string.isRequired,
};

export default connect(state => ({
    upanel: state.auth.upanel,
    userrole: state.auth.userrole,
}))(UserPanel);
