import React, { Component } from 'react';
/* eslint-disable  */
import { Alert, Row, Col, Card, CardBody, CardTitle, CardSubtitle, Modal, Button, Table } from "reactstrap";

import MetaTags from 'react-meta-tags';
import axios from 'axios';
// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"
import { ACA_URL } from '../../../config/QueryMe';
// import SweetAlert from "react-bootstrap-sweetalert"
import Switch from 'react-switch';
import Select from 'react-select';
import swal from 'sweetalert';
//Import Breadcrumb

const initialstate = {
  itempageabout: 'Cities | Database',
  itemmainname: 'State',
  itemsubname: 'Cities',
  itemsubonename: 'City',
  mitemsubonename: 'State',
  itemactionname: 'Add City',
  tname: 'dbcity',
  tdepname: 'dummy',
  tmdepname: 'dbsta',
  itemvalue: '',
  mitemvalue: '',
  select_mitems: [{
    value: '',
    label: (<div>
      Select Category
    </div>
    ),
  }],
  allitems: [],
  allmitems: [],
  relateditems: [],
  showAlert: false,
  alertType: '',
  alertText: '',
  keyId: 0,
  keyName: '',
  oldkeyname: '',
  newkeyname: '',
  noorid: 0,

  addKeyModal: false,
  akeys: 0,
  bkeys: 0,
  tkeys: 0,
};
class DbCity extends Component {
  constructor(props) {
    super(props)
    // this.state = {}
    this.handleGetkeys = this.handleGetkeys.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleDkey = this.handleDkey.bind(this);
    this.handleTkey = this.handleTkey.bind(this);
    this.handleEditkey = this.handleEditkey.bind(this);

  }
  state = { ...initialstate };
  componentDidMount() {

    this.handleGetkeys();


  }

  handleChange = (event) => {
    const { name, value } = event.target;
    console.log(name + ' ' + value);
    this.setState({ [name]: value });
  };
  handleGetkeys = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      uid: localStorage.usercode,
      table: this.state.tname,
      deptable: this.state.tdepname,
      mtable: this.state.tmdepname,
    };
    const getuurl = ACA_URL + 'getmitems';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (!response.data.error) {
          localStorage.setItem("token", response.data.token);
          const allitems = [...response.data.details];
          console.log('allitems', response.data.details);

          var allmitems = [];
          let akeys = 0;

          let tkeys = 0;
          allitems.map((dkey) => {
            akeys = akeys + dkey.status;
            tkeys = tkeys + 1;
            if (dkey.status == 1) {
              allmitems.push({ id: dkey.id, mitemname: dkey.mitemname });
            }
          });

          this.setState({
            akeys: akeys,
            bkeys: tkeys - akeys,
            tkeys: tkeys,
            allmitems: allmitems,
            relateditems: allitems,
            select_mitems: [{
              value: '',
              label: (<div>
                Select Category
              </div>
              ),
            }],
          }, () => {
            allmitems.map((opmitems) => {
              this.state.select_mitems.push({
                value: opmitems.id,
                label: (
                  <div>
                    {opmitems.mitemname}
                  </div>
                ),
              });
            });
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        // localStorage.clear();
        //this.props.history.push('/'); //eslint-disable-line
      });
  };

  handleValidSubmit = (event, values) => {
    event.preventDefault();
    console.log('values', values);
    if (this.state.mitemvalue == '') {
      this.setState({
        showAlert: true,
        alertType: 'warning',
        alertText: 'Select ' + this.state.mitemsubonename,
      });
    } else {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: localStorage.token,
      };
      var addkeyurl = ACA_URL + 'addmitem';
      var postData = {
        item: values.item,
        mitem: this.state.mitemvalue,
        uid: localStorage.usercode,
        table: this.state.tname,
        mtable: this.state.tmdepname,

      };
      if (this.state.editmode) {
        addkeyurl = ACA_URL + 'editmitem';
        postData = {
          itemid: this.state.editid,
          item: values.item,
          mitem: this.state.mitemvalue,
          uid: localStorage.usercode,
          table: this.state.tname,
          mtable: this.state.tmdepname,

        };
      }


      axios
        .post(addkeyurl, postData, {
          headers: headers,
        })
        .then((response) => {
          console.log(response.data.message);
          if (response.data.error) {
            this.setState({
              showAlert: true,
              alertType: 'warning',
              alertText: response.data.message,
            });
          } else {
            localStorage.setItem("token", response.data.token);
            this.handleGetkeys();
            this.setState(
              {
                addKeyModal: false,
              });
            swal(values.item + ' ' + response.data.message, {
              icon: 'success',
            });
          }
        })
        .catch((error) => {
          console.log('Error is ', error);
          this.setState({
            alertType: 'danger',
            showAlert: true,
            alertText: 'Failed Try Again Later!',
          });
        });

    }
  };

  handleDkey = (id, item, checked) => {
    console.log(checked + 'checked');
    console.log(id + 'id');
    console.log(item + 'item');

    swal({
      title: 'Delete ' + item,
      text: 'Are you sure?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((keyconfirm) => {
      if (keyconfirm) {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: localStorage.token,
        };

        const delkeyurl = ACA_URL + 'delitem';
        var postData = {
          keyid: id,
          uid: localStorage.usercode,
          table: this.state.tname,
        };
        axios
          .post(delkeyurl, postData, {
            headers: headers,
          })
          .then((response) => {
            console.log(response.data.message);

            if (response.data.error) {
              swal(item + ' ' + response.data.message, {
                icon: 'warning',
              });
            } else {
              localStorage.setItem("token", response.data.token);
              this.handleGetkeys();
              swal(item + ' ' + response.data.message, {
                icon: 'success',
              });
            }
          })
          .catch((error) => {
            console.log('Error is ', error);
            swal('Try Again Later', {
              icon: 'warning',
            });
          });
      }
    });
  };
  handleTkey = (id, item, checked) => {
    console.log(checked + 'checked');
    console.log(id + 'id');
    console.log(item + 'item');
    var keysts = checked == true ? 1 : 0;
    var kstatus = 'Deactivate';
    if (checked) {
      kstatus = 'Activate';
    }
    swal({
      title: kstatus + ' ' + item,
      text: 'Are you sure?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((keyconfirm) => {
      if (keyconfirm) {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: localStorage.token,
        };

        const togkeyurl = ACA_URL + 'togitem';
        var postData = {
          keyid: id,
          kstatus: keysts,
          uid: localStorage.usercode,
          table: this.state.tname,

        };
        axios
          .post(togkeyurl, postData, {
            headers: headers,
          })
          .then((response) => {
            console.log(response.data.message);

            if (response.data.error) {
              swal(item + ' ' + response.data.message, {
                icon: 'warning',
              });
            } else {
              localStorage.setItem("token", response.data.token);
              this.handleGetkeys();
              swal(item + ' ' + response.data.message, {
                icon: 'success',
              });
            }
          })
          .catch((error) => {
            console.log('Error is ', error);
            swal('Try Again Later', {
              icon: 'Danger',
            });
          });
      }
    });
  };

  handleAkey = () => {
    this.setState({
      addKeyModal: true,
      itemactionname: 'Add ' + this.state.itemsubonename,
      editmode: false,
      itemvalue: '',
      mitemvalue: '',
    });
  };
  handleCkey = () => {
    this.setState({
      addKeyModal: false,
      editmode: false,
      itemvalue: '',
      mitemvalue: '',

    });
  };
  handleEditkey = (id, item, mitem, checked) => {
    console.log(checked + 'checked');
    console.log(id + 'id');
    console.log(item + 'item');
    console.log(mitem + 'mitem');
    this.setState({
      addKeyModal: true,
      editmode: true,
      editid: id,
      itemvalue: item,
      mitemvalue: mitem,
      itemactionname: 'Edit ' + this.state.itemsubonename,
    });
  };

  handleSelectMitemChange = (selectedOption) => {
    let mitemvalue = selectedOption.value;
    this.setState({ mitemvalue: mitemvalue });
  };

  render() {

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{this.state.itempageabout} </title>
          </MetaTags>
          <div className="container-fluid">

            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <CardTitle className="h4">{this.state.itemsubname}
                      <Button
                        className="float-end btn-sm"
                        color="success"
                        onClick={this.handleAkey}
                      >
                        +
                      </Button>
                    </CardTitle>
                    <p className="card-title-desc">Some Description</p>


                  </CardBody>
                </Card>
              </Col>
              {this.state.relateditems &&
                this.state.relateditems.map((mitem, index) => {
                  var allitems = mitem.itemdetails;
                  return (
                    <Col className="col-12" key={index}>
                      <Card>
                        <CardBody>
                          <CardTitle className="h4">{mitem.mitemname}

                          </CardTitle>


                          <div className="table-responsive">
                            <Table className="table mb-0">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  <th>{this.state.itemsubonename}</th>
                                  <th>date Added</th>
                                  <th colSpan='2'>Manage</th>
                                </tr>
                              </thead>
                              <tbody>
                                {allitems &&
                                  allitems.map((item, index) => {
                                    var ksts = false;
                                    if (item.status == 1) {
                                      ksts = true;
                                    }

                                    return (
                                      <tr key={index}>
                                        <th scope="row">{index + 1}</th>
                                        <td>
                                          <span className="float-start"
                                            style={{ marginRight: '10px', cursor: 'pointer' }}
                                            onClick={this.handleEditkey.bind(
                                              this,
                                              item.id,
                                              item.itemname,
                                              mitem.id,
                                            )}
                                          >
                                            <i className="mdi mdi-pencil d-block font-size-16"></i>{" "}{" "}
                                          </span>
                                          <strong>{" "}{item.itemname}</strong>
                                        </td>
                                        <td>{item.posted}</td>
                                        <td>
                                          <Switch
                                            height={15}
                                            width={40}
                                            onChange={this.handleTkey.bind(
                                              this,
                                              item.id,
                                              item.itemname,
                                            )}
                                            checked={ksts}

                                          />
                                        </td>

                                        <td>

                                          {item.del == 0 ? (
                                            <button
                                              className="btn btn-danger btn-sm"
                                              color="primary"
                                              id={item.id}
                                              onClick={this.handleDkey.bind(
                                                this,
                                                item.id,
                                                item.itemname,
                                              )}
                                            >
                                              <i className="mdi mdi-trash-can d-block font-size-16"></i>{" "}
                                            </button>
                                          ) : null}
                                        </td>
                                      </tr>
                                    );
                                  })}

                              </tbody>
                            </Table>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  )
                })}
            </Row>
          </div>
          <Modal
            isOpen={this.state.addKeyModal}

            centered={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0">{this.state.itemactionname} </h5>
              <button
                type="button"
                onClick={this.handleCkey}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">


              <AvForm
                className="needs-validation"
                onValidSubmit={this.handleValidSubmit}
              >
                {this.state.showAlert ? (
                  <Alert color={this.state.alertType}>
                    <p>{this.state.alertText}</p>
                  </Alert>
                ) : null}

                <div className="mb-3">

                  <div className="form__form-group">
                    <span className="form__form-group-label">{this.state.mitemsubonename} </span>
                    <div className="form__form-group-field" style={{ height: '30px' }}>

                      <Select
                        name="mitem"
                        value={this.state.select_mitems.filter(
                          (option) => option.value == this.state.mitemvalue,
                        )}
                        onChange={this.handleSelectMitemChange}
                        options={this.state.select_mitems}
                        clearable={false}
                        className="react-select-team"
                        classNamePrefix="react-select"
                      // defaultMenuIsOpen={true}
                      // isDisabled={true}
                      />
                    </div>
                  </div>

                </div>
                <div className="mb-3">
                  <AvField
                    name="item"
                    label={this.state.itemsubonename}
                    value={this.state.itemvalue}
                    type="text"
                    required
                    placeholder={'Enter ' + this.state.itemsubonename}
                  />
                </div>



                <div className="mt-4 d-grid">
                  <button
                    className="btn btn-primary btn-block waves-effect waves-light"
                    type="submit"
                  >
                    {this.state.itemactionname}
                  </button>
                </div>


              </AvForm>
            </div>
          </Modal>
        </div>
      </React.Fragment>
    )
  }
}

export default DbCity
