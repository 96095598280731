import React, { Component } from 'react';
/* eslint-disable */
import { Alert, CardImg, Modal } from "reactstrap";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import swal from 'sweetalert';

// availity-reactstrap-validation
import { AvForm } from "availity-reactstrap-validation";
import { IMG_URL, NO_IMG } from "../config/QueryMe";

class CropImgUpload extends Component {
  constructor(props) {
    super(props)
    let imgsrc = this.props.imgsrc;
    console.log('imgsrc', imgsrc);
    if (!this.props.imgsrc) {
      imgsrc = NO_IMG;
    }
    this.state = {
      showAlert: false,
      alertType: '',
      alertText: '',
      photo: imgsrc,
      profilesrc: null,
      crop: {
        unit: "%",
        width: 30,
        aspect: this.props.imgcropw / this.props.imgcroph
      }
    }




    this.handlepic = this.handlepic.bind(this);
    this.handleProfileFile = this.handleProfileFile.bind(this);
  }

  componentDidMount() {

  }




  uploadProfileImage = (event) => {
    console.log(this.state.photo);
    var formData = new FormData();
    formData.append('file', this.state.croppedprofileImage);
    formData.append('item', this.props.onwhich);
    formData.append('uid', localStorage.usercode);
    formData.append('w', this.props.imgw);
    formData.append('h', this.props.imgh);
    formData.append('token', localStorage.token);
    // console.log(event.target.files[0]);
    let proimgurl = IMG_URL;
    fetch(proimgurl, {
      method: 'POST',
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {

        console.log('data', data);
        if (data.error) {
          console.log('data.error', data.error);
          swal(item + ' ' + data.message, {
            icon: 'warning',
          });
        } else {
          localStorage.setItem("token", data.token);
          localStorage.setItem(this.props.onwhich, data.urlimage);
          this.setState(
            {
              photo: data.urlimage,
              addKeyModal: false,
            },
            () => {
              console.log(this.state.photo);
            },
          );
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };


  onImageLoaded = image => {
    this.imageRef = image
  }

  onCropChange = (crop) => {
    this.setState({ crop });
  }

  onProfileCropComplete = crop => {
    if (this.imageRef && crop.width && crop.height) {
      this.getCroppedImg(this.imageRef, crop, 'profile');
    }
  }


  getCroppedImg(image, crop, whichimg) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    )

    const reader = new FileReader()
    canvas.toBlob(blob => {
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        this.dataURLtoFileProfile(reader.result, whichimg)
      }
    })
  }

  dataURLtoFileProfile(dataurl) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    var imgext = mime.split('/');
    var imgfilename = this.props.onwhich;
    let cropedfilename = imgfilename + '.' + imgext[1];
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], cropedfilename, { type: mime });
    this.setState({ croppedprofileImage: croppedImage }, () => {
      console.log('croppedImage', this.state.croppedprofileImage);
      console.log('profilemime', mime);
      console.log('profilecropedfilename', cropedfilename);

    })
  }

  handleCkey = () => {
    this.setState({
      addKeyModal: false,
      croppedprofileImage: '',

    });
  };
  handlepic = (ofwhich) => {
    this.setState({
      addKeyModal: true,
      ofwhich: ofwhich,
      croppedprofileImage: '',
    });
  };
  handleProfileFile = e => {
    const fileReader = new FileReader()
    fileReader.onloadend = () => {
      this.setState({
        profilesrc: fileReader.result,
      })
    }
    fileReader.readAsDataURL(e.target.files[0])
  }


  render() {
    const { crop, profilesrc } = this.state

    return (
      <React.Fragment>

        <CardImg top className="avatar-lg onclicklink" src={this.state.photo} alt={this.state.imgtitle} onClick={this.handlepic.bind(this, this.props.onwhich)} />



        <Modal
          isOpen={this.state.addKeyModal}

          centered={true}
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">{this.props.imgtitle} </h5>
            <button
              type="button"
              onClick={this.handleCkey}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <AvForm
              className="needs-validation"
              onValidSubmit={this.uploadProfileImage}
            >
              {this.state.showAlert ? (
                <Alert color={this.state.alertType}>
                  <p>{this.state.alertText}</p>
                </Alert>
              ) : null}
              <div className="mb-3">
                {profilesrc && (
                  <ReactCrop
                    src={profilesrc}
                    crop={crop}
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onProfileCropComplete}
                    onChange={this.onCropChange}
                  />
                )}
              </div>
              <div className="mb-3">
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <input type="file" onChange={this.handleProfileFile} />
                  </div>
                </div>
              </div>
              <div className="mt-4 d-grid">
                <button
                  className="btn btn-primary btn-block waves-effect waves-light"
                  type="submit"
                >
                  Crop and Update {this.state.imgtitle}
                </button>
              </div>
            </AvForm>

          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

export default CropImgUpload
