import React, { Component } from 'react';
/* eslint-disable  */
import { Alert, Row, Col, Card, CardBody, CardTitle, CardImg, CardText, CardSubtitle, Modal, Button, FormGroup, InputGroup, Label, Table } from "reactstrap";

import MetaTags from 'react-meta-tags';
import axios from 'axios';
// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"
import { VHS_URL, NO_IMG } from '../../../../config/QueryMe';
import CropImgUpload from '../../../CropImgUpload';
// import SweetAlert from "react-bootstrap-sweetalert"

// import Select from 'react-select';
import swal from 'sweetalert';



class TableMng extends Component {
  constructor(props) {
    super(props)
    this.state = {
      itempageabout: 'Add Table | Venue',
      itemlablename: 'Table',
      tname: 'vhsptysp',
      tdepname: 'dummy',
      showAlert: false,
      alertType: '',
      alertText: '',

      itemvalue: '',
      capacity: '',
      party_space_description: '',
      editmode: false,
      editid: '',
      diagram_1: '',
      diagram_2: '',
      diagram_3: '',
      diagram_4: '',
      diagram_5: '',

    }

    this.handleValidSubmit = this.handleValidSubmit.bind(this);

  }

  componentDidMount() {
    localStorage.setItem('diagram_1', '');
    localStorage.setItem('diagram_2', '');
    localStorage.setItem('diagram_3', '');
    localStorage.setItem('diagram_4', '');
    localStorage.setItem('diagram_5', '');

    this.handleGetkeys();
  }

  handleBack = () => {
    if (localStorage.vhsid) {
      this.props.history.push('/venueview'); //eslint-disable-line
    } else {

      // localStorage.setItem('spid', '');
      localStorage.setItem('whattodovhs', 'Add Venue');
      this.props.history.push('/venues'); //eslint-disable-line
    }

    // localStorage.setItem('whattodosp', 'Add Space');

  };

  handleGetkeys = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      uid: localStorage.usercode,
      table: this.state.tname,
      spid: localStorage.spid,
    };
    const getuurl = VHS_URL + 'getitemspdetails';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (!response.data.error) {
          localStorage.setItem("token", response.data.token);

          var venuespdata = response.data.allitems;

          if (localStorage.spid) {


            this.setState({
              editmode: true,
              editid: venuespdata.id,
              itemvalue: venuespdata.itemname,
              capacity: venuespdata.capacity,
              party_space_description: venuespdata.party_space_description,
              diagram_1: venuespdata.diagram_1,
              diagram_2: venuespdata.diagram_2,
              diagram_3: venuespdata.diagram_3,
              diagram_4: venuespdata.diagram_4,
              diagram_5: venuespdata.diagram_5,
              showAlert: false,

            }, () => {
              localStorage.setItem('diagram_1', venuespdata.diagram_1);
              localStorage.setItem('diagram_2', venuespdata.diagram_2);
              localStorage.setItem('diagram_3', venuespdata.diagram_3);
              localStorage.setItem('diagram_4', venuespdata.diagram_4);
              localStorage.setItem('diagram_5', venuespdata.diagram_5);
            });

          } else {

            this.setState({
              editmode: false,
            });
          }


        }
      })
      .catch((error) => {
        console.log('error', error);
        // localStorage.clear();
        //this.props.history.push('/venues'); //eslint-disable-line
      });
  };

  handleValidSubmit = (event, values) => {
    event.preventDefault();
    console.log('values', values);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var addkeyurl = VHS_URL + 'additemsp';
    if (!localStorage.diagram_1) {
      swal("Select Diagram 1", {
        icon: 'warning',
      });
    } else {
      var postData = {
        item: values.item,
        uid: localStorage.usercode,
        table: this.state.tname,
        venue_id: localStorage.vhsid,
        capacity: values.capacity,
        party_space_description: values.party_space_description,
        diagram_1: localStorage.diagram_1,
        diagram_2: localStorage.diagram_2,
        diagram_3: localStorage.diagram_3,
        diagram_4: localStorage.diagram_4,
        diagram_5: localStorage.diagram_5,
      };
      if (this.state.editmode) {
        addkeyurl = VHS_URL + 'edititemsp';
        postData = {
          itemid: this.state.editid,
          item: values.item,
          uid: localStorage.usercode,
          table: this.state.tname,
          capacity: values.capacity,
          party_space_description: values.party_space_description,
          diagram_1: localStorage.diagram_1,
          diagram_2: localStorage.diagram_2,
          diagram_3: localStorage.diagram_3,
          diagram_4: localStorage.diagram_4,
          diagram_5: localStorage.diagram_5,

        };
      }


      axios
        .post(addkeyurl, postData, {
          headers: headers,
        })
        .then((response) => {
          console.log(response.data.message);
          if (response.data.error) {
            this.setState({
              showAlert: true,
              alertType: 'warning',
              alertText: response.data.message,
            });
          } else {
            this.setState({
              showAlert: false,
              alertType: 'warning',
              alertText: '',
            });
            localStorage.setItem("token", response.data.token);
            localStorage.setItem('spid', '');

            swal(values.item + ' ' + response.data.message, {
              icon: 'success',
            });
            this.props.history.push('/venueview'); //eslint-disable-line
          }
        })
        .catch((error) => {
          console.log('Error is ', error);
          this.setState({
            alertType: 'danger',
            showAlert: true,
            alertText: 'Failed Try Again Later!',
          });
        });
    }
  };






  render() {

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{localStorage.whattodosp} </title>
          </MetaTags>
          <div className="container-fluid">


            <Row>
              <Col className="col-12">


                <Card>
                  <div className="card__title">
                    <Button
                      color="success" size="sm"
                      className="float-right"
                      onClick={this.handleBack}
                    >
                      Back to Venues
                    </Button>
                    <h5 className="bold-text">{localStorage.whattodosp}</h5>
                    {/* <h5 className="subhead">Use default table with property <span className="red-text">responsive</span></h5> */}

                  </div>
                  <CardBody>
                    <Row>
                      <Col md={2}>
                        <div className="mb-3" style={{ marginRight: '10px', cursor: 'pointer' }}>
                          <p style={{ fontWeight: 'bold' }}>Diagram 1</p>
                          <Row>
                            <CropImgUpload imgsrc={localStorage.diagram_1} className="img-thumbnail onclicklink" onwhich='diagram_1' imgtitle='Diagram 1' imgcropw={0} imgcroph={0} imgw={0} imgh={0} />
                          </Row>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="mb-3" style={{ marginRight: '10px', cursor: 'pointer' }}>
                          <p style={{ fontWeight: 'bold' }}>Diagram 2</p>
                          <Row>
                            <CropImgUpload imgsrc={localStorage.diagram_2} className="img-thumbnail onclicklink" onwhich='diagram_2' imgtitle='Diagram 2' imgcropw={0} imgcroph={0} imgw={0} imgh={0} />
                          </Row>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="mb-3" style={{ marginRight: '10px', cursor: 'pointer' }}>
                          <p style={{ fontWeight: 'bold' }}>Diagram 3</p>
                          <Row>
                            <CropImgUpload imgsrc={localStorage.diagram_3} className="img-thumbnail onclicklink" onwhich='diagram_3' imgtitle='Diagram 3' imgcropw={0} imgcroph={0} imgw={0} imgh={0} />
                          </Row>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="mb-3" style={{ marginRight: '10px', cursor: 'pointer' }}>
                          <p style={{ fontWeight: 'bold' }}>Diagram 4</p>
                          <Row>
                            <CropImgUpload imgsrc={localStorage.diagram_4} className="img-thumbnail onclicklink" onwhich='diagram_4' imgtitle='Diagram 4' imgcropw={0} imgcroph={0} imgw={0} imgh={0} />
                          </Row>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="mb-3">
                          <p style={{ fontWeight: 'bold' }}>Diagram 5</p>
                          <Row>
                            <CropImgUpload imgsrc={localStorage.diagram_5} className="img-thumbnail onclicklink" onwhich='diagram_5' imgtitle='Diagram 5' imgcropw={0} imgcroph={0} imgw={0} imgh={0} />

                          </Row>
                        </div>
                      </Col>

                    </Row>
                    <AvForm
                      className="needs-validation"
                      onValidSubmit={this.handleValidSubmit}
                    >
                      {this.state.showAlert ? (
                        <Alert color={this.state.alertType}>
                          <p>{this.state.alertText}</p>
                        </Alert>
                      ) : null}
                      <Row>

                        <Col md={6}>
                          <div className="mb-3 mandate">
                            <AvField
                              name="item"
                              label={this.state.itemlablename}
                              value={this.state.itemvalue}
                              type="text"
                              required
                              placeholder={'Enter ' + this.state.itemlablename}
                            />
                          </div>
                        </Col>

                        <Col md={4}>
                          <div className="mb-3 mandate">
                            <AvField
                              name="capacity"
                              label='Capacity'
                              value={this.state.capacity}
                              type="text"
                              placeholder={'Enter capacity'}
                              required
                            />
                          </div>
                        </Col>

                        <Col md={12}>
                          <div className="mb-3 mandate">
                            <AvField name="party_space_description" label='Description' value={this.state.party_space_description} type="textarea" required placeholder={'Enter  Description'} />
                          </div>
                        </Col>

                      </Row>
                      <div className="mt-4 d-grid">
                        <button
                          className="btn btn-primary float-right waves-effect waves-light"
                          type="submit"
                        >
                          {localStorage.whattodosp}
                        </button>
                      </div>


                    </AvForm>


                  </CardBody>
                </Card>
              </Col>
            </Row>

          </div>

        </div>
      </React.Fragment>
    )
  }
}

export default TableMng
