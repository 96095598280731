/* eslint-disable  */
const fakeUsers = [
  {
    mobile: '9700764323',
    otp: '123456',
    fullName: 'Roman Johanson',
    avatar: `${process.env.PUBLIC_URL}/img/fake_avatar.png`,
  },
];
// const fakeUsers = [
//   {
//     email: 'admin@admin.com',
//     password: 'admin',
//     fullName: 'Roman Johanson',
//     avatar: `${process.env.PUBLIC_URL}/img/fake_avatar.png`,
//   },
// ];
export default fakeUsers;
