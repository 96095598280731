import React, { Component } from 'react';
/* eslint-disable  */
import { Alert, Row, Col, Card, CardBody, CardTitle, CardImg, CardText, CardSubtitle, Modal, Button, FormGroup, InputGroup, Label, Table } from "reactstrap";

import MetaTags from 'react-meta-tags';
import axios from 'axios';
// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"

import { VHS_URL, NO_IMG } from '../../../../config/QueryMe';
import CropImgUpload from '../../../CropImgUpload';
// import SweetAlert from "react-bootstrap-sweetalert"

import Select from 'react-select';
import swal from 'sweetalert';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";


class EventMng extends Component {
  constructor(props) {
    super(props)
    this.state = {
      itempageabout: 'Add Event | Management',
      itemlablename: 'Event Title',
      tname: 'evnt',
      tdepname: 'dummy',
      showAlert: false,
      alertType: '',
      alertText: '',
      event_date_from_name: 'Event Date',

      // select_eventtype: [{
      //   value: '',
      //   label: (<div>
      //     Select Event Type
      //   </div>
      //   ),
      // }],

      ismultidayevnt: false,

      itemvalue: '',
      eventtypevalue: '',
      event_typeID: '',
      event_description: '',
      event_thumbnail_image: '',
      // event_flyer: '',
      event_cover_image: '',
      event_date_from: new Date(),
      event_time_from: new Date(),
      event_timings: '',
      event_mng_orgname: '',
      event_mng_designation: '',
      event_mng_name: '',
      event_mng_mobile: '',
      event_mng_email: '',
      event_t_and_c: '',
      // event_date_to: '',
      // event_time_to: '',
      // event_eli_enter: '',
      // event_eli_drink: '',
      // event_eli_vip_badge: '',
      // event_eli_age_badge: '',
      // event_mng_address: '',
      // event_mng_country: '',
      // event_mng_state: '',
      // event_mng_city: '',
      // event_mng_pincode: '',
      // event_mng_picture: '',
      // event_sm_fb: '',
      // event_sm_insta: '',
      // event_sm_tweet: '',
      // event_sm_youtube: '',

    }
    this.handleAkey = this.handleAkey.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    // this.handleSelecteventtypeChange = this.handleSelecteventtypeChange.bind(this);
    this.handleDatetime = this.handleDatetime.bind(this);
  }

  componentDidMount() {
    if (localStorage.evntid == '') {
      if (localStorage.spsid == '' || localStorage.vhsid == '') {
        // this.props.history.push('/venues'); //eslint-disable-line
      }
    } else {

      this.handleGetkeys();
    }

  }

  handleAkey = () => {
    localStorage.setItem('evntid', '');
    localStorage.setItem('whattodoevnt', 'Add Event');
    this.props.history.push('/events'); //eslint-disable-line
  };

  handleGetkeys = () => {


    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      uid: localStorage.usercode,
      table: this.state.tname,
      evntid: localStorage.evntid,
    };
    const getuurl = VHS_URL + 'getitemevntdetails';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (!response.data.error) {
          localStorage.setItem("token", response.data.token);
          const alleventtypes = [...response.data.eventtypes];
          var eventdata = response.data.allitems.items;
          var eventdataa = response.data.allitems.items;

          if (localStorage.evntid) {

            let event_time_from = new Date(eventdataa.event_time_from);
            let event_date_from = new Date(eventdataa.event_date_from);
            this.setState({
              editmode: true,
              editid: eventdata.id,
              itemvalue: eventdataa.ce_event,
              event_description: eventdata.event_description,
              event_thumbnail_image: eventdata.event_thumbnail_image,
              // event_flyer: '',
              event_cover_image: eventdata.event_cover_image,
              event_date_from: event_date_from,
              event_time_from: event_time_from,
              event_timings: eventdata.timings,
              event_mng_orgname: eventdata.event_mng_orgname,
              event_mng_designation: eventdata.event_mng_designation,
              event_mng_name: eventdata.event_mng_name,
              event_mng_mobile: eventdata.event_mng_mobile,
              event_mng_email: eventdata.event_mng_email,
              event_t_and_c: eventdata.event_t_and_c,
              eventtypevalue: parseInt(eventdataa.event_typeID, 0),
              showAlert: false,
              select_eventtype: alleventtypes,

            });
          } else {
            this.setState({
              editmode: false,
              select_eventtype: alleventtypes,
            });
          }


        }
      })
      .catch((error) => {
        console.log('error', error);
        // localStorage.clear();
        //this.props.history.push('/Events'); //eslint-disable-line
      });
  };

  handleValidSubmit = (event, values) => {
    event.preventDefault();
    console.log('values', values);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var addkeyurl = VHS_URL + 'additemevnt';
    if (!localStorage.event_thumbnail_image) {
      swal("Select Event Thumbnail", {
        icon: 'warning',
      });
      // } else if (!localStorage.event_flyer) {
      //   swal("Select Event Flyer", {
      //     icon: 'warning',
      //   });
    } else if (!localStorage.event_cover_image) {
      swal("Select Event banner", {
        icon: 'warning',
      });
      // } else if (!this.state.eventtypevalue) {
      //   swal("Select Event Type", {
      //     icon: 'warning',
      //   });
    } else {
      var postData = {
        item: values.item,
        uid: localStorage.usercode,
        table: this.state.tname,
        venue_id: localStorage.vhsid,
        sps_id: localStorage.spsid,
        eventtype: this.state.eventtypevalue,
        event_description: values.event_description,
        event_date_from: this.state.event_date_from,
        event_time_from: this.state.event_time_from,
        event_timings: values.event_timings,
        event_mng_orgname: values.event_mng_orgname,
        event_mng_designation: values.event_mng_designation,
        event_mng_name: values.event_mng_name,
        event_mng_mobile: values.event_mng_mobile,
        event_mng_email: values.event_mng_email,
        event_t_and_c: values.event_t_and_c,
        event_thumbnail_image: localStorage.event_thumbnail_image,
        // event_flyer: localStorage.event_flyer,
        event_cover_image: localStorage.event_cover_image,
      };
      if (this.state.editmode) {
        addkeyurl = VHS_URL + 'edititemevnt';
        postData = {
          itemid: this.state.editid,
          item: values.item,
          uid: localStorage.usercode,
          table: this.state.tname,
          eventtype: this.state.eventtypevalue,
          event_description: values.event_description,
          event_date_from: this.state.event_date_from,
          event_time_from: this.state.event_time_from,
          event_timings: values.event_timings,
          event_mng_orgname: values.event_mng_orgname,
          event_mng_designation: values.event_mng_designation,
          event_mng_name: values.event_mng_name,
          event_mng_mobile: values.event_mng_mobile,
          event_mng_email: values.event_mng_email,
          event_t_and_c: values.event_t_and_c,
          event_thumbnail_image: localStorage.event_thumbnail_image,
          // event_flyer: localStorage.event_flyer,
          event_cover_image: localStorage.event_cover_image,

        };
      }


      axios
        .post(addkeyurl, postData, {
          headers: headers,
        })
        .then((response) => {
          console.log(response.data.message);
          if (response.data.error) {
            this.setState({
              showAlert: true,
              alertType: 'warning',
              alertText: response.data.message,
            });
          } else {
            this.setState({
              showAlert: false,
              alertType: 'warning',
              alertText: '',
            });
            localStorage.setItem("token", response.data.token);


            swal(values.item + ' ' + response.data.message, {
              icon: 'success',
            });
            this.props.history.push('/Events'); //eslint-disable-line
          }
        })
        .catch((error) => {
          console.log('Error is ', error);
          this.setState({
            alertType: 'danger',
            showAlert: true,
            alertText: 'Failed Try Again Later!',
          });
        });
    }
  };

  // handleSelecteventtypeChange = (selectedOption) => {
  //   console.log('selectedOption', selectedOption);
  //   let eventtypevalue = selectedOption.value;
  //   let ismultidayevnt = false;
  //   let event_date_from_name = "Event Date";
  //   if (eventtypevalue == 7 || eventtypevalue == 12) {
  //     ismultidayevnt = false;
  //     event_date_from_name = "Event Date From";
  //   }
  //   this.setState({ eventtypevalue: eventtypevalue, eventtypevaluename: selectedOption.label, ismultidayevnt: ismultidayevnt, event_date_from_name: event_date_from_name });
  // };

  handleDatetime = (fdate, f) => {

    console.log('f', f);
    console.log('fdate', fdate);
    // console.log('fdate', format(fdate, 'dd-MM-yyyy'));

    if (f == 'event_date_from') {

      this.setState({ event_date_from: fdate });
    } else if (f == 'event_date_to') {

      this.setState({ event_date_to: fdate });
    } else if (f == 'event_time_from') {

      this.setState({ event_time_from: fdate });
    } else if (f == 'event_time_to') {

      this.setState({ event_time_to: fdate });
    }

  };


  render() {

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{localStorage.whattodoevnt} </title>
          </MetaTags>
          <div className="container-fluid">


            <Row>
              <Col className="col-12">


                <Card>
                  <div className="card__title">
                    <Button
                      color="success" size="sm"
                      className="float-right"
                      onClick={this.handleAkey}
                    >
                      Back to Events
                    </Button>
                    <h5 className="bold-text">{this.state.itempageabout}</h5>
                    {/* <h5 className="subhead">Use default table with property <span className="red-text">responsive</span></h5> */}

                  </div>
                  <CardBody>
                    <Row>
                      <Col md={4}>
                        <div className="mb-3">
                          <p>Event Thumbnail</p>
                          <Row>
                            <Col md={6}>
                              <CropImgUpload imgsrc={localStorage.event_thumbnail_image} className="img-thumbnail onclicklink" onwhich='event_thumbnail_image' imgtitle='Event Thumbnail Image' imgcropw={1} imgcroph={1} imgw={250} imgh={250} />

                            </Col>
                            <Col md={6}>
                            </Col>
                          </Row>
                        </div>

                      </Col>

                      {/* <Col md={4}>
                        <p>Event Flyer</p>
                        <div className="mb-3">
                          <Row>
                            <Col md={6}>
                              <CropImgUpload imgsrc={localStorage.event_flyer} className="img-thumbnail onclicklink" onwhich='event_flyer' imgtitle='Event Flyer Image' imgcropw={1} imgcroph={1} imgw={250} imgh={250} />

                            </Col>
                            <Col md={6}>
                            </Col>
                          </Row>
                        </div>
                      </Col> */}



                      <Col md={4}>
                        <p>Event Banner</p>
                        <div className="mb-3">
                          <Row>
                            <Col md={6}>
                              <CropImgUpload imgsrc={localStorage.event_cover_image} className="img-thumbnail onclicklink" onwhich='event_cover_image' imgtitle='Event Banner Image' imgcropw={2} imgcroph={1} imgw={250} imgh={250} />

                            </Col>
                            <Col md={6}>
                            </Col>
                          </Row>
                        </div>

                      </Col>
                    </Row>
                    <AvForm
                      className="needs-validation"
                      onValidSubmit={this.handleValidSubmit}
                    >
                      {this.state.showAlert ? (
                        <Alert color={this.state.alertType}>
                          <p>{this.state.alertText}</p>
                        </Alert>
                      ) : null}


                      <Row>

                        <Col md={12}>
                          <div className="mb-3 mandate">
                            <AvField
                              name="item"
                              label={this.state.itemlablename}
                              value={this.state.itemvalue}
                              type="text"
                              required
                              placeholder={'Enter ' + this.state.itemlablename}
                            />
                          </div>
                        </Col>

                        {/* <Col md={4}>
                          <div className="mb-3">
                            <div className="form-group mandate">
                              <label className="">Event Type</label>

                              <Select
                                name="eventtypevalue"
                                value={this.state.select_eventtype.filter(
                                  (option) => option.value == this.state.eventtypevalue,
                                )}
                                onChange={this.handleSelecteventtypeChange}
                                options={this.state.select_eventtype}
                                clearable={false}
                                className="react-select-team"
                                classNamePrefix="react-select"

                              />

                            </div>
                          </div>
                        </Col> */}

                        <Col md={6}>
                          <div className="mb-3">
                            <AvField name="event_mng_orgname" label='Event Organization Name' value={this.state.event_mng_orgname} type="text" required placeholder={'Enter Organization Name'} />
                          </div>
                        </Col>

                        <Col md={3}>
                          <div className="mb-3 mandate">
                            <label for="event_date_from" class="form-label">{this.state.event_date_from_name}</label>
                            <DatePicker
                              // closeOnScroll={true}
                              minDate={new Date()}
                              selected={this.state.event_date_from}
                              onChange={(date) => this.handleDatetime(date, "event_date_from")} className="form-control"
                              dateFormat="dd/MM/yyyy"
                            />
                          </div>
                        </Col>

                        {this.state.ismultidayevnt ? (
                          <>

                            <Col md={3}>
                              <div className="mb-3 mandate">
                                <label for="event_date_to" class="form-label">Event date to</label>
                                <DatePicker
                                  // closeOnScroll={true}
                                  minDate={this.state.event_date_from}
                                  selected={this.state.event_date_from}
                                  onChange={(date) => this.handleDatetime(date, "event_date_to")} className="form-control"
                                  dateFormat="dd/MM/yyyy"
                                />
                              </div>
                            </Col>

                            <Col md={3}>
                              <div className="mb-3 mandate">
                                <label for="event_time_to" class="form-label">Event Close Time</label>
                                <DatePicker
                                  // closeOnScroll={true}
                                  minDate={this.state.event_date_from}
                                  selected={this.state.event_date_from}
                                  onChange={(date) => this.handleDatetime(date, "event_time_to")}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  timeIntervals={15}
                                  timeCaption="Time"
                                  dateFormat="h:mm aa"
                                  className="form-control"
                                />
                              </div>
                            </Col>
                          </>

                        ) : null}

                        <Col md={3}>
                          <div className="mb-3 mandate">
                            <label for="event_time_from" class="form-label">Event Start Time</label>
                            <DatePicker
                              // closeOnScroll={true}
                              minDate={this.state.event_date_from}
                              selected={this.state.event_time_from}
                              onChange={(date) => this.handleDatetime(date, "event_time_from")}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              dateFormat="h:mm aa"
                              className="form-control"
                            />
                          </div>
                        </Col>

                        <Col md={12}>
                          <div className="mb-3">
                            <AvField name="event_timings" label='Event Time in Brief' value={this.state.event_timings} type="text" required placeholder={'Enter Time in Brief'} />
                          </div>
                        </Col>


                        <Col md={6}>
                          <div className="mb-3">
                            <AvField name="event_mng_orgname" label='Event Organization Name' value={this.state.event_mng_orgname} type="text" required placeholder={'Enter Organization Name'} />
                          </div>
                        </Col>




                        <Col md={6}>
                          <div className="mb-3">
                            <AvField name="event_mng_name" label='Contact Person Name' value={this.state.event_mng_name} type="text" required placeholder={'Enter Contact Person'} />
                          </div>
                        </Col>


                        <Col md={4}>
                          <div className="mb-3">
                            <AvField name="event_mng_designation" label='Contact Person Designation' value={this.state.event_mng_designation} type="text" required placeholder={'Enter Designation'} />
                          </div>
                        </Col>




                        <Col md={4}>
                          <div className="mb-3 mandate">
                            <AvField
                              name="event_mng_mobile"
                              label='Contact Person Mobile'
                              value={this.state.event_mng_mobile}
                              type="number"

                              placeholder={'Enter Mobile'}
                            />
                          </div>
                        </Col>


                        <Col md={4}>
                          <div className="mb-3 mandate">
                            <AvField
                              name="event_mng_email"
                              label='Contact Person Email'
                              value={this.state.event_mng_email}
                              type="email"
                              placeholder={'Enter Email'}
                            />
                          </div>
                        </Col>

                        <Col md={12}>
                          <div className="mb-3 mandate">
                            <AvField name="event_description" label='Event Description' value={this.state.event_description} type="textarea" required placeholder={'Enter  Event Description'} />
                          </div>
                        </Col>
                        {/* <Col md={12}>
                          <div className="mb-3 mandate">
                            <AvField name="event_mng_address" label='Organization Address' value={this.state.event_mng_address} type="textarea" required placeholder={'Enter  Organization Address'} />
                          </div>
                        </Col> */}

                        <Col md={12}>
                          <div className="mb-3 mandate">
                            <AvField name="event_t_and_c" label='Event Terms & Conditions' value={this.state.event_t_and_c} type="textarea" required placeholder={'Enter  Terms & Conditions'} />
                          </div>
                        </Col>

                      </Row>
                      <div className="mt-4 d-grid">
                        <button
                          className="btn btn-primary float-right waves-effect waves-light"
                          type="submit"
                        >
                          {localStorage.whattodoevnt}
                        </button>
                      </div>


                    </AvForm>


                  </CardBody>
                </Card>
              </Col>
            </Row>

          </div>

        </div>
      </React.Fragment>
    )
  }
}

export default EventMng
