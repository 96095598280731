import React, { Component } from 'react';
/* eslint-disable  */
import { Link, withRouter } from "react-router-dom"
import axios from 'axios';
import PinWithInfoWindow from '../../../Maps/GoogleMap/components/PinWithInfoWindow';
import { VHS_URL } from '../../../../config/QueryMe';
import Switch from 'react-switch';
import {
  Card, CardBody, Col, Badge, Row, Table, Button
} from 'reactstrap';
const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

//Lightbox
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
const initialstate = {
  itempageabout: 'Venue Management',
  itemmainname: 'Venue Type',
  itemsubname: 'Venue Management',
  itemsubonename: 'Venue',
  mitemsubonename: 'Venue Type',
  itemactionname: 'Venue Registration',
  tname: 'vhs',
  tdepname: 'dummy',
  itemvalue: '',
  userrole: '',
  name: '',
  mobile: '',
  role: 'all',

  select_mitems: [{
    value: '',
    label: (<div>
      Select Type
    </div>
    ),
  }],
  allitems: [],
  allmitems: [],
  venue: [],
  showAlert: false,
  alertType: '',
  alertText: '',
  keyId: 0,
  keyName: '',
  oldkeyname: '',
  newkeyname: '',
  noorid: 0,

  addKeyModal: false,
  akeys: 0,
  bkeys: 0,
  tkeys: 0,
  isFits: false,
};
class VenueView extends Component {

  state = { ...initialstate };
  constructor(props) {
    super(props)

    this.handleGetkeys = this.handleGetkeys.bind(this);
    this.handleAkey = this.handleAkey.bind(this);
    this.handleEditkey = this.handleEditkey.bind(this);
    this.handleTkey = this.handleTkey.bind(this);
    localStorage.setItem('spid', '');
    localStorage.setItem('venue_logo', '');
    localStorage.setItem('venue_building', '');
    localStorage.setItem('venue_banner', '');
    this.handleGetkeys();
  }

  componentDidMount() {

  }
  handleAkey = () => {
    localStorage.setItem('whattodosp', 'Add Space');
    this.props.history.push('/mngptyspace'); //eslint-disable-line
  };



  handleEditkey = (vhsid) => {
    localStorage.setItem('whattodovhs', 'Edit Venue');

    this.props.history.push('/venuemng'); //eslint-disable-line
  };
  handleSpEditkey = (spid) => {
    localStorage.setItem('whattodosp', 'Edit Space');
    localStorage.setItem('spid', spid);
    this.props.history.push('/mngptyspace'); //eslint-disable-line
  };

  handleGetkeys = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      uid: localStorage.usercode,
      table: this.state.tname,
      deptable: this.state.tdepname,
      vhsid: localStorage.vhsid,
    };
    const getuurl = VHS_URL + 'getitemsvhsone';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (!response.data.error) {
          localStorage.setItem("token", response.data.token);
          const allitems = response.data.allitems;
          console.log('allitems', response.data.allitems);

          let hospi = allitems.items;
          const latlong = hospi.hlatlong.split(",");
          const latt = parseFloat(latlong[0]);
          const longg = parseFloat(latlong[1]);
          this.setState({
            allitems: allitems,
            venue: allitems.items,
            hlat: latt,
            hlong: longg,

          }, () => {
            localStorage.setItem('venue_logo', hospi.venue_logo);
            localStorage.setItem('venue_building', hospi.venue_building);
            localStorage.setItem('venue_banner', hospi.venue_banner);
          });

        }
      })
      .catch((error) => {
        console.log('error', error);
        // localStorage.clear();
        //this.props.history.push('/'); //eslint-disable-line
      });
  };
  handleTkey = (id, item, checked) => {
    console.log(checked + 'checked');
    console.log(id + 'id');
    console.log(item + 'item');
    var keysts = checked == true ? 1 : 0;
    var kstatus = 'Deactivate';
    if (checked) {
      kstatus = 'Activate';
    }
    swal({
      title: kstatus + ' ' + item,
      text: 'Are you sure?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((keyconfirm) => {
      if (keyconfirm) {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: localStorage.token,
        };

        const togkeyurl = VHS_URL + 'togitem';
        var postData = {
          keyid: id,
          kstatus: keysts,
          uid: localStorage.usercode,
          table: 'vhsptysp',

        };
        axios
          .post(togkeyurl, postData, {
            headers: headers,
          })
          .then((response) => {
            console.log(response.data.message);

            if (response.data.error) {
              swal(item + ' ' + response.data.message, {
                icon: 'warning',
              });
            } else {
              localStorage.setItem("token", response.data.token);
              this.handleGetkeys();
              swal(item + ' ' + response.data.message, {
                icon: 'success',
              });
            }
          })
          .catch((error) => {
            console.log('Error is ', error);
            swal('Try Again Later', {
              icon: 'Danger',
            });
          });
      }
    });
  };


  render() {
    const { venue, allitems } = this.state;
    // console.log('venue', allitems.party_spaces);



    return (
      <>

        {this.state.isFits ? (
          <Lightbox
            mainSrc={this.state.lightimg}
            enableZoom={false}
            imagePadding={30}
            wrapperClassName="lightboxwrapper"
            imageCaption={
              "Caption. Can be aligned it to any side and contain any HTML."
            }
            onCloseRequest={() => this.setState({ isFits: false })}
          />
        ) : null}
        <Col xs={12} lg={12} xl={12}>
          <Card>
            <CardBody style={{ padding: '15px' }}>
              <Row>
                <Col xs={3} lg={1} style={{ paddingRight: '0px' }}>


                  <img
                    src={allitems.venue_logo}
                    alt={allitems.itemname}
                    onClick={() => {
                      this.setState({
                        isFits: true,
                        lightimg: allitems.venue_logo
                      });
                    }}
                  />

                </Col>
                <Col xs={9} lg={6}>
                  <p className="profile__activity-name">
                    <span
                      style={{ cursor: 'pointer', float: 'left', marginRight: '10px' }}
                      onClick={this.handleEditkey.bind(
                        this,
                        allitems.id,
                      )}
                    >
                      <i className="mdi mdi-pencil d-block font-size-16"></i>{" "}{" "}
                    </span>
                    {allitems.itemname}
                  </p>
                  <p className="profile__activity-name">
                    <span className="profile__activity-date" style={{ fontSize: '12px', marginRight: '10px' }}> {venue.posted} {' '} <Badge className={venue.status == 1 ? 'badge badge-success' : 'badge badge-danger'} >{venue.status == 1 ? 'Active' : 'In Active'}</Badge></span>
                  </p>
                  <p className="bold-text">Type: {allitems.venuetype}</p>
                </Col>
                <Col xs={12} lg={2}>
                  <p className="bold-text">Building </p>
                  <img
                    src={venue.venue_building}
                    alt={allitems.itemname}
                    onClick={() => {
                      this.setState({
                        isFits: true,
                        lightimg: venue.venue_building
                      });
                    }}
                  />


                </Col>
                <Col xs={12} lg={2}>
                  <p className="bold-text">Banner</p>
                  <img
                    src={venue.venue_banner}
                    alt={allitems.itemname}
                    onClick={() => {
                      this.setState({
                        isFits: true,
                        lightimg: venue.venue_banner
                      });
                    }}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>

        <Col>
          <Card>
            <CardBody style={{ padding: '15px' }}>

              <Button
                color="success" size="sm"
                className="float-right smallbtn"
                onClick={this.handleAkey}
              >
                +
              </Button>
              <h5 className="bold-text">Space List</h5>



            </CardBody>
          </Card>

        </Col>
        <Col xs={12}>
          <Row>
            {allitems.party_spaces &&
              allitems.party_spaces.map((party_space, index) => {

                var usts = true;
                party_space.status = parseInt(party_space.status, 0);
                if (party_space.status === 0) {
                  usts = false;
                }
                return (
                  <Col xs={12} lg={4} xl={4}>
                    <Card>
                      <CardBody style={{ padding: '15px' }}>
                        <div className="card__title">
                          <span
                            style={{ cursor: 'pointer', float: 'left', marginRight: '10px' }}
                            onClick={this.handleSpEditkey.bind(
                              this,
                              party_space.id
                            )}
                          >
                            <i className="mdi mdi-pencil d-block font-size-16"></i>{" "}{" "}
                          </span>

                          <Switch
                            height={15}
                            width={40}
                            className="float-right"
                            onChange={this.handleTkey.bind(
                              this,
                              party_space.id,
                              party_space.party_space,
                            )}
                            checked={usts}
                          />
                          <h5 className="bold-text">{party_space.party_space}</h5>
                          <h5 className="subhead">Capacity <span className="red-text">{party_space.capacity}</span></h5>
                        </div>
                        <Row>

                          <Col xs={2}>
                            <img
                              src={party_space.diagram_1}
                              alt={party_space.party_space}
                              onClick={() => {
                                this.setState({
                                  isFits: true,
                                  lightimg: party_space.diagram_1
                                });
                              }}
                            />
                          </Col>
                          <Col xs={2}>
                            <img
                              src={party_space.diagram_2}
                              alt={party_space.party_space}
                              onClick={() => {
                                this.setState({
                                  isFits: true,
                                  lightimg: party_space.diagram_2
                                });
                              }}
                            />
                          </Col>
                          <Col xs={2}>
                            <img
                              src={party_space.diagram_3}
                              alt={party_space.party_space}
                              onClick={() => {
                                this.setState({
                                  isFits: true,
                                  lightimg: party_space.diagram_3
                                });
                              }}
                            />
                          </Col>
                          <Col xs={2}>
                            <img
                              src={party_space.diagram_4}
                              alt={party_space.party_space}
                              onClick={() => {
                                this.setState({
                                  isFits: true,
                                  lightimg: party_space.diagram_4
                                });
                              }}
                            />
                          </Col>
                          <Col xs={2}>
                            <img
                              src={party_space.diagram_5}
                              alt={party_space.party_space}
                              onClick={() => {
                                this.setState({
                                  isFits: true,
                                  lightimg: party_space.diagram_5
                                });
                              }}
                            />
                          </Col>
                        </Row>
                        <Badge className={party_space.status == 1 ? 'float-right badge badge-success' : 'float-right badge badge-danger'} >{party_space.status == 1 ? 'Active' : 'In Active'} </Badge>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
          </Row>
        </Col>



        <Col xs={12} md={6}>
          <Card>
            <CardBody style={{ padding: '15px' }}>
              <Table striped>

                <tbody>
                  <tr>
                    <th scope="row"> Mobile: </th>
                    <td>{venue.venue_mobile}</td>
                  </tr>
                  <tr>
                    <th scope="row"> Email: </th>
                    <td>{venue.venue_email}</td>
                  </tr>
                  <tr>
                    <th scope="row"> Opening - Closing: </th>
                    <td>{venue.opening_time}{' - '}
                      {venue.closing_time}</td>
                  </tr>
                  <tr>
                    <th scope="row"> Timings: </th>
                    <td>{venue.timings}</td>
                  </tr>
                  <tr>
                    <th scope="row"> Address: </th>
                    <td>{venue.venue_address}</td>
                  </tr>

                </tbody>
              </Table>


            </CardBody>
          </Card>
        </Col>


        <PinWithInfoWindow hname={allitems.itemname} haddress={venue.venue_address} vlat={this.state.hlat} vlong={this.state.hlong} />




        <Col md={12} lg={12} xl={12}>
          <Card>
            <CardBody style={{ padding: '15px' }}>
              <div className="card__title">
                <h5 className="bold-text">About {allitems.itemname}</h5>
              </div>
              {venue.venue_description}




            </CardBody>
          </Card>

        </Col>

      </>
    );
  }
}


export default withRouter(VenueView)
