import React, { Component } from 'react';
/* eslint-disable  */
import { Link, withRouter } from "react-router-dom"
import axios from 'axios';

import { CU_URL } from '../../../../config/QueryMe';
import {
  Card, CardBody, Col, Badge, Table, Row, Button,
} from 'reactstrap';
import Switch from 'react-switch';
const initialstate = {
  itempageabout: 'Club Users Management',
  tname: 'cu',
  itemtype: 'vhstype',
  item: 'venue',
  allitems: [],

  showAlert: false,
  alertType: '',
  alertText: '',
  keyId: 0,
  keyName: '',
  oldkeyname: '',
  newkeyname: '',
  noorid: 0,

  addKeyModal: false,
  akeys: 0,
  bkeys: 0,
  tkeys: 0,
  isFits: false,
};
class UsersclubList extends Component {
  constructor(props) {
    super(props)

    this.handleGetkeys = this.handleGetkeys.bind(this);

    this.handleTkey = this.handleTkey.bind(this);

    this.handleAkey = this.handleAkey.bind(this);

    localStorage.setItem('whattodocu', 'Add Club User');
    localStorage.setItem('cuid', '');


  }

  state = { ...initialstate };
  componentDidMount() {
    this.handleGetkeys();
  }

  handleAkey = () => {
    localStorage.setItem('cuid', '');
    localStorage.setItem('whattodocu', 'Add Club User');
    this.props.history.push('/usersclubmng'); //eslint-disable-line
  };

  handleViewCu = (cuid) => {

    localStorage.setItem('cuid', cuid);
    this.props.history.push('/usersclubview'); //eslint-disable-line
  };

  handleGetkeys = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      uid: localStorage.usercode,
      table: this.state.tname,
      itemtype: this.state.itemtype,
      item: this.state.item,
    };
    const getuurl = CU_URL + 'getitemscu';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (!response.data.error) {
          localStorage.setItem("token", response.data.token);
          const allitems = [...response.data.allitems];
          console.log('allitems', response.data.allitems);

          this.setState({
            allitems: allitems,
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        // localStorage.clear();
        //this.props.history.push('/'); //eslint-disable-line
      });
  };

  handleTkey = (id, item, checked) => {
    console.log(checked + 'checked');
    console.log(id + 'cuid');
    console.log(item + 'item');
    var keysts = checked == true ? 1 : 0;
    var kstatus = 'Deactivate';
    if (checked) {
      kstatus = 'Activate';
    }
    swal({
      title: kstatus + ' ' + item,
      text: 'Are you sure?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((keyconfirm) => {
      if (keyconfirm) {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: localStorage.token,
        };

        const togkeyurl = CU_URL + 'cutogitem';
        var postData = {
          keyid: id,
          kstatus: keysts,
          uid: localStorage.usercode,
          table: this.state.tname,

        };
        axios
          .post(togkeyurl, postData, {
            headers: headers,
          })
          .then((response) => {
            console.log(response.data.message);

            if (response.data.error) {
              swal(item + ' ' + response.data.message, {
                icon: 'warning',
              });
            } else {
              localStorage.setItem("token", response.data.token);
              this.handleGetkeys();
              swal(item + ' ' + response.data.message, {
                icon: 'success',
              });
            }
          })
          .catch((error) => {
            console.log('Error is ', error);
            swal('Try Again Later', {
              icon: 'Danger',
            });
          });
      }
    });
  };



  render() {


    return (
      <>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>

              <div className="card__title">
                <Button
                  color="success" size="sm"
                  className="float-right"
                  onClick={this.handleAkey}
                >
                  +
                </Button>
                <h5 className="bold-text">{this.state.itempageabout}</h5>
                {/* <h5 className="subhead">Use default table with property <span className="red-text">responsive</span></h5> */}

              </div>

            </CardBody>
          </Card>
        </Col>


        <Col md={12} style={{ marginBottom: '20px' }}>
          <CardBody style={{ padding: '10px' }}>
            <div className="table-responsive">
              <Table className="table mb-0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Sno.</th>
                    <th>Role</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Manage</th>
                    <th>Updated</th>
                    <th>Added</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.allitems &&
                    this.state.allitems.map((item, index) => {
                      var usts = true;
                      if (item.status == 0) {
                        usts = false;
                      }
                      console.log('item.status', usts);
                      return (
                        <tr key={index}>
                          <th scope="row"><span className="float-start"
                            style={{ marginRight: '10px', cursor: 'pointer' }}
                            onClick={this.handleViewCu.bind(
                              this,
                              item.id,
                            )}
                          >
                            <i className="mdi mdi-eye d-block font-size-16"></i>{" "}{" "}
                          </span></th>
                          <th scope="row">{index + 1}</th>
                          <td>
                            {item.cu_role}
                          </td>
                          <td>
                            <strong>{" "}{item.itemname}{" ("}{item.venues}{")"}</strong>
                          </td>
                          <td>{item.cu_email}</td>
                          <td>{item.cu_mobile}</td>
                          <td>
                            <Switch
                              onChange={this.handleTkey.bind(
                                this,
                                item.id,
                                item.itemname,
                              )}
                              checked={usts}
                              height={15}
                              width={40}
                            />
                          </td>
                          <td>
                            {item.cu_updated}
                          </td>
                          <td>
                            {item.posted}
                          </td>

                        </tr>
                      );
                    })}

                </tbody>
              </Table>
            </div>
          </CardBody>
        </Col>

      </>
    );
  }
}


export default withRouter(UsersclubList)
