import React from 'react';
/* eslint-disable  */
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import FirebaseIcon from 'mdi-react/FirebaseIcon';
import fakeUsers from '../App/fakeUsers';
import { handleLogin, handleAuthError, handleAuthAsdstatus } from '@/redux/actions/authActions';
import RegisterForm from './components/RegisterForm';
import axios from 'axios';
import { MY_REGIS_URL, MY_REGOTP_URL } from '../../config/QueryMe';

// const auth0Icon = `${process.env.PUBLIC_URL}/img/auth0.svg`;

const Register = ({
  // changeIsOpenModalFireBase,
  login,
  handleError,
  handleASDstatus,
  error,
  asdstatus,
}) => {

  const onSubmit = ({ name, mobile, email, otp }) => {
    // const user = fakeUsers.find(item => item.mobile === mobile && item.otp === otp);
    console.log('name', name);
    console.log('mobile', mobile);
    console.log('email', email);
    console.log('otp', otp);
    if (name != '' && mobile != '' && email != '' && !otp) {
      if (name != '' && mobile != '' && email != '') {
        if (!isNaN(mobile) && mobile.length == 10) {
          const headers = {
            'Content-Type': 'application/json',
          };
          var postData = {
            name: name,
            mobile: mobile,
            email: email,
            showtab: 'u',
          };
          const loginurl = MY_REGIS_URL;
          axios
            .post(loginurl, postData, {
              headers: headers,
            })
            .then((response) => {
              console.log('Response is ', response.data);
              console.log(response.data.message);
              if (response.data.error) {
                localStorage.setItem('message', response.data.message);
                handleError('error');
              } else {
                localStorage.setItem('message', 'Enter OTP Sent to Mobile');
                localStorage.setItem('showtab', response.data.showtab);
                handleError('success');
                handleASDstatus('otpbox');
              }
            })
            .catch((error) => {
              console.log('Error is ', error);
              localStorage.setItem('message', error);
              handleError('error');
            });
        } else {
          localStorage.setItem('message', 'Enter Only 10 Digits');
        }
      }

    } else if (mobile && otp) {
      if (otp) {

        if (!isNaN(otp) && otp.length == 6) {
          const headers = {
            'Content-Type': 'application/json',
          };
          var postData = {
            otp: otp,
            name: name,
            mobile: mobile,
            email: email,
            showtab: localStorage.showtab,
          };
          const loginurl = MY_REGOTP_URL;
          axios
            .post(loginurl, postData, {
              headers: headers,
            })
            .then((response) => {
              console.log('Response is ', response.data);
              console.log(response.data.message);
              if (response.data.error) {
                localStorage.setItem('message', response.data.message);
                handleError('error');
              } else {
                const userdetails = response.data.data;
                let avatar = `${process.env.PUBLIC_URL}/img/fake_avatar.png`;
                if (userdetails.image_url == '') {
                  avatar = userdetails.image_url;
                }

                login({
                  fullName: userdetails.name,
                  avatar: avatar,
                  mobile: userdetails.mobile,
                  email: userdetails.email,
                  userrole: userdetails.role,
                  usercode: response.data.usercode,
                  upanel: response.data.upanel,
                  token: response.data.token
                });
              }
            })
            .catch((error) => {
              console.log('Error is ', error);
              localStorage.setItem('message', error);
              handleError('error');
            });
        } else {
          localStorage.setItem('message', 'OTP  6 Digits');
        }
      }

    }



  };


  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">
              <span className="account__logo"> COVER
                <span className="account__logo-accent"> ENTRY</span>
              </span>
            </h3>
            <h4 className="account__subhead subhead">Cover Yourself</h4>
          </div>
          <RegisterForm onSubmit={onSubmit} error={error} asdstatus={asdstatus} />
          <div className="account__have-account">
            <p>Already have an account? <NavLink to="/">Login</NavLink></p>
          </div>


        </div>
      </div>
    </div>
  );
};

Register.propTypes = {

  login: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  handleASDstatus: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  asdstatus: PropTypes.string.isRequired,
};

export default connect(state => ({
  error: state.auth.error,
  asdstatus: state.auth.asdstatus,
}), { login: handleLogin, handleError: handleAuthError, handleASDstatus: handleAuthAsdstatus })(Register);
