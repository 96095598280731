import React from 'react';
/* eslint-disable  */
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';

const SidebarContent = ({
  onClick,
  changeToDark,
  changeToLight,
  sidebarCollapse,
}) => (
  <div className="sidebar__content">
    {/* <ul className="sidebar__block">
      <SidebarCategory title="Theme" icon="layers" sidebarCollapse={sidebarCollapse}>
        <button type="button" className="sidebar__link" onClick={changeToLight}>
          <p className="sidebar__link-title">Light Theme</p>
        </button>
        <button type="button" className="sidebar__link" onClick={changeToDark}>
          <p className="sidebar__link-title">Dark Theme</p>
        </button>
      </SidebarCategory>
    </ul> */}

    <ul className="sidebar__block">
      {/* <SidebarLink title="Add Clubs" route="/pages/one" onClick={onClick} /> */}
      <SidebarLink icon="diamond" title="Venues" route="/cvenues" onClick={onClick} />
      <SidebarLink icon="diamond" title="Events" route="/cevents" onClick={onClick} />
      <SidebarLink icon="diamond" title="User Management" route="/cusermng" onClick={onClick} />
      <SidebarLink icon="diamond" title="Club Users Management" route="/usersclub" onClick={onClick} />
      <SidebarLink icon="diamond" title="Reports" route="/creports" onClick={onClick} />
    </ul>
    <ul className="sidebar__block">
      <SidebarCategory title="Inputs" icon="layers" sidebarCollapse={sidebarCollapse}>
        <SidebarLink title="Action Button Lables" route="/btnlables" onClick={onClick} />
        <SidebarLink title="Venue Types" route="/venuetypes" onClick={onClick} />
        <SidebarLink title="Event Types" route="/eventtypes" onClick={onClick} />
        <SidebarLink title="Space Types" route="/spacetypes" onClick={onClick} />
        <SidebarLink title="Table Types" route="/tabletypes" onClick={onClick} />
        <SidebarLink title="Ticket Types" route="/tickettypes" onClick={onClick} />
        <SidebarLink title="Genders" route="/genders" onClick={onClick} />
        <SidebarLink title="Notification Types" route="/notificationtypes" onClick={onClick} />
        <SidebarLink title="Notification Intvel" route="/notificationintvels" onClick={onClick} />

      </SidebarCategory>
    </ul>
    <ul className="sidebar__block">
      <SidebarCategory title="Locations" icon="layers" sidebarCollapse={sidebarCollapse}>
        <SidebarLink title="Countries" route="/countries" onClick={onClick} />
        <SidebarLink title="States" route="/cstates" onClick={onClick} />
        <SidebarLink title="Cities" route="/cities" onClick={onClick} />

      </SidebarCategory>
    </ul>
  </div>
);

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
};

export default SidebarContent;
