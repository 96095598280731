import React from 'react';
/* eslint-disable  */
import PropTypes from 'prop-types';
import {
  Route, Switch, Redirect, withRouter,
} from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';
import { changeThemeToDark, changeThemeToLight } from '@/redux/actions/themeActions';
import { changeMobileSidebarVisibility, changeSidebarVisibility } from '@/redux/actions/sidebarActions';
import { SidebarProps } from '@/shared/prop-types/ReducerProps';

import Dashboard from './Dashboard/index';
import CVenues from './CVenues/index';
import Usersclub from './Usersclub/index';
import UsersclubMng from './Usersclub/components/UsersclubMng';
import UserclubView from './Usersclub/components/UserclubView';
import CEvents from './CEvents/index';
import CUsermng from './CUsermng/index';
import CReports from './CReports/index';


import DbGender from './Db/DbGender';
import DbNotificationType from './Db/DbNotificationType';
import DbNotificationIntvel from './Db/DbNotificationIntvel';
import DbCountry from './Db/DbCountry';
import DbVenueType from './Db/DbVenueType';
import DbEventType from './Db/DbEventType';
import DbSpaceType from './Db/DbSpaceType';
import DbTicketType from './Db/DbTicketType';
import DbTableType from './Db/DbTableType';
import DbBtnLable from './Db/DbBtnLable';
import DbState from './Db/DbState';
import DbCity from './Db/DbCity';

const LayoutAdmin = ({ dispatch, sidebar }) => {
  const layoutClass = classNames({
    layout: true,
    'layout--collapse': sidebar.collapse,
  });

  const sidebarVisibility = () => {
    dispatch(changeSidebarVisibility());
  };

  const mobileSidebarVisibility = () => {
    dispatch(changeMobileSidebarVisibility());
  };

  const changeToDark = () => {
    dispatch(changeThemeToDark());
  };

  const changeToLight = () => {
    dispatch(changeThemeToLight());
  };
  const Pages = () => (
    <Switch>

      <Route path="/dashboard" component={Dashboard} />
    </Switch>
  );
  return (
    <>
      <div className={layoutClass}>
        <Topbar
          changeMobileSidebarVisibility={mobileSidebarVisibility}
          changeSidebarVisibility={sidebarVisibility}
        />
        <Sidebar
          sidebar={sidebar}
          changeToDark={changeToDark}
          changeToLight={changeToLight}
          changeMobileSidebarVisibility={mobileSidebarVisibility}
        />
      </div>
      <div className="container__wrap">
        <Route path="/dashboard" component={Pages} />
        <Route path="/cvenues" component={CVenues} />
        <Route path="/usersclub" component={Usersclub} />
        <Route path="/usersclubmng" component={UsersclubMng} />
        <Route path="/usersclubview" component={UserclubView} />
        <Route path="/cevents" component={CEvents} />
        <Route path="/cusermng" component={CUsermng} />
        <Route path="/creports" component={CReports} />


        <Route path="/genders" component={DbGender} />
        <Route path="/notificationtypes" component={DbNotificationType} />
        <Route path="/notificationintvels" component={DbNotificationIntvel} />
        <Route path="/venuetypes" component={DbVenueType} />
        <Route path="/eventtypes" component={DbEventType} />
        <Route path="/spacetypes" component={DbSpaceType} />
        <Route path="/tickettypes" component={DbTicketType} />
        <Route path="/tabletypes" component={DbTableType} />
        <Route path="/btnlables" component={DbBtnLable} />
        <Route path="/countries" component={DbCountry} />
        <Route path="/cstates" component={DbState} />
        <Route path="/cities" component={DbCity} />
      </div>
    </>
  );
};

LayoutAdmin.propTypes = {
  dispatch: PropTypes.func.isRequired,
  sidebar: SidebarProps.isRequired,
};

export default withRouter(connect(state => ({
  sidebar: state.sidebar,
}))(LayoutAdmin));
