import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import AlternateEmailIcon from 'mdi-react/AlternateEmailIcon';
import MobilePhoneBasicIcon from 'mdi-react/MobilePhoneBasicIcon';
import { Button, Alert } from 'reactstrap';

const RegisterForm = ({ onSubmit, error, asdstatus }) => (
  <Form onSubmit={onSubmit}>
    {({ handleSubmit }) => (
      <form className="form" onSubmit={handleSubmit}>
        {error === 'success' ? (
          <Alert
            className="w-100"
            color="success"
          >
            {localStorage.message}
          </Alert>
        ) : null}
        {error === 'error' ? (
          <Alert
            className="w-100"
            color="danger"
          >
            {localStorage.message}
          </Alert>
        ) : null}
        {/* <Alert
          className="w-100"
          color="danger"
          isOpen={!!error}
        >
          {error}
        </Alert> */}
        {asdstatus === 'userbox' ? (
          <>
            <div className="form__form-group">
              <span className="form__form-group-label">Name</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <AccountOutlineIcon />
                </div>
                <Field
                  name="name"
                  component="input"
                  type="text"
                  placeholder="Enter Your Name"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Mobile</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <MobilePhoneBasicIcon />
                </div>
                <Field
                  name="mobile"
                  component="input"
                  type="number"
                  placeholder="Enter Mobile Number"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Email</span>
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <AlternateEmailIcon />
                </div>
                <Field
                  name="email"
                  component="input"
                  type="email"
                  placeholder="Enter Email"
                />
              </div>
            </div>
          </>

        ) : null}
        {asdstatus === 'otpbox' ? (
          <div className="form__form-group">
            <span className="form__form-group-label">Enter OTP</span>
            <div className="form__form-group-field">
              <Field
                name="otp"
                component="input"
                type="number"
                placeholder="Enter OTP"
              />
            </div>
          </div>
        ) : null}


        <Button className="account__btn" type="submit" color="primary">Create Account</Button>

      </form>
    )}
  </Form>
);

RegisterForm.propTypes = {
  error: PropTypes.string,
  asdstatus: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

RegisterForm.defaultProps = {
  error: '',
  asdstatus: '',
};

export default RegisterForm;
