import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { Button, Alert } from 'reactstrap';
import renderCheckBoxField from '@/shared/components/form/CheckBox';

const LogInForm = ({ onSubmit, error, asdstatus }) => (
  <Form onSubmit={onSubmit}>
    {({ handleSubmit }) => (
      <form className="form" onSubmit={handleSubmit}>
        {error === 'success' ? (
          <Alert
            className="w-100"
            color="success"
          >
            {localStorage.message}
          </Alert>
        ) : null}
        {error === 'error' ? (
          <Alert
            className="w-100"
            color="danger"
          >
            {localStorage.message}
          </Alert>
        ) : null}
        {/* <Alert
          className="w-100"
          color="danger"
          isOpen={!!error}
        >
          {error}
        </Alert> */}
        {asdstatus === 'userbox' ? (
          <div className="form__form-group">
            <span className="form__form-group-label">Mobile</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <AccountOutlineIcon />
              </div>
              <Field
                name="mobile"
                component="input"
                type="number"
                value="9700764323"
                placeholder="Enter Mobile Number"
              />
            </div>
          </div>
        ) : null}
        {asdstatus === 'otpbox' ? (
          <div className="form__form-group">
            <span className="form__form-group-label">Enter OTP</span>
            <div className="form__form-group-field">
              <Field
                name="otp"
                component="input"
                type="number"
                value="123456"
                placeholder="Enter OTP"
              />
            </div>
          </div>
        ) : null}

        <div className="form__form-group">
          <div className="form__form-group-field">
            <Field
              name="remember_me"
              component={renderCheckBoxField}
              label="Remember me"
              type="checkbox"
            />
          </div>
        </div>
        <Button className="account__btn" type="submit" color="primary">Sign In</Button>

      </form>
    )}
  </Form>
);

LogInForm.propTypes = {
  error: PropTypes.string,
  asdstatus: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

LogInForm.defaultProps = {
  error: '',
  asdstatus: '',
};

export default LogInForm;
