import React from 'react';
/* eslint-disable  */
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import FirebaseIcon from 'mdi-react/FirebaseIcon';
import fakeUsers from '../App/fakeUsers';
import { handleLogin, handleAuthError, handleAuthAsdstatus } from '@/redux/actions/authActions';
import LogInForm from './components/LogInForm';
import axios from 'axios';
import { MY_LOGIN_URL, MY_OTP_URL } from '../../config/QueryMe';
import withAuthFirebase from './components/auth/withAuthFirebase';

// const auth0Icon = `${process.env.PUBLIC_URL}/img/auth0.svg`;

const LogIn = ({
  // changeIsOpenModalFireBase,
  login,
  handleError,
  handleASDstatus,
  error,
  asdstatus,
}) => {

  const onSubmit = ({ mobile, otp }) => {
    // const user = fakeUsers.find(item => item.mobile === mobile && item.otp === otp);
    console.log('mobile', mobile);
    console.log('otp', otp);
    if (mobile && !otp) {
      if (mobile) {
        if (!isNaN(mobile) && mobile.length == 10) {
          const headers = {
            'Content-Type': 'application/json',
          };
          var postData = {
            mobile: mobile,
            showtab: 'cu',
          };
          const loginurl = MY_LOGIN_URL;
          axios
            .post(loginurl, postData, {
              headers: headers,
            })
            .then((response) => {
              console.log('Response is ', response.data);
              console.log(response.data.message);
              if (response.data.error) {
                localStorage.setItem('message', response.data.message);
                handleError('error');
              } else {
                localStorage.setItem('message', 'Enter OTP Sent to Mobile');
                localStorage.setItem('showtab', response.data.showtab);
                handleError('success');
                handleASDstatus('otpbox');
              }
            })
            .catch((error) => {
              console.log('Error is ', error);
              localStorage.setItem('message', error);
              handleError('error');
            });
        } else {
          localStorage.setItem('message', 'Enter Only 10 Digits');
        }
      }

    } else if (mobile && otp) {
      if (otp) {

        if (!isNaN(otp) && otp.length == 6) {
          const headers = {
            'Content-Type': 'application/json',
          };
          var postData = {
            otp: otp,
            mobile: mobile,
            showtab: localStorage.showtab,
          };
          const loginurl = MY_OTP_URL;
          axios
            .post(loginurl, postData, {
              headers: headers,
            })
            .then((response) => {
              console.log('Response is ', response.data);
              console.log(response.data.message);
              if (response.data.error) {
                localStorage.setItem('message', response.data.message);
                handleError('error');
              } else {
                const userdetails = response.data.data;
                let avatar = `${process.env.PUBLIC_URL}/img/fake_avatar.png`;
                if (userdetails.image_url == '') {
                  avatar = userdetails.image_url;
                }

                login({
                  fullName: userdetails.cu_user,
                  avatar: avatar,
                  mobile: userdetails.cu_mobile,
                  email: userdetails.cu_email,
                  userrole: userdetails.cu_role,
                  usercode: response.data.usercode,
                  upanel: response.data.upanel,
                  token: response.data.token
                });
              }
            })
            .catch((error) => {
              console.log('Error is ', error);
              localStorage.setItem('message', error);
              handleError('error');
            });
        } else {
          localStorage.setItem('message', 'OTP  6 Digits');
        }
      }

    }



  };


  return (
    <div className="account">
      <div className="account__wrapper">
        <div className="account__card">
          <div className="account__head">
            <h3 className="account__title">
              <span className="account__logo"> COVER
                <span className="account__logo-accent"> ENTRY</span>
              </span>
            </h3>
            <h4 className="account__subhead subhead">Cover Yourself</h4>
          </div>
          <LogInForm onSubmit={onSubmit} error={error} asdstatus={asdstatus} />
          <div className="account__have-account">
            <p>New User? Create Account <NavLink to="/register">Register</NavLink></p>
          </div>
        </div>
      </div>
    </div>
  );
};

LogIn.propTypes = {
  login: PropTypes.func.isRequired,
  handleError: PropTypes.func.isRequired,
  handleASDstatus: PropTypes.func.isRequired,
  error: PropTypes.string.isRequired,
  asdstatus: PropTypes.string.isRequired,
};

export default withAuthFirebase(connect(state => ({
  error: state.auth.error,
  asdstatus: state.auth.asdstatus,
}), { login: handleLogin, handleError: handleAuthError, handleASDstatus: handleAuthAsdstatus })(LogIn));
