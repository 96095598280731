import React, { useState } from 'react';
/* eslint-disable  */
import { Card, CardBody, Col } from 'reactstrap';
import "react-datepicker/dist/react-datepicker.css";
// import { isMobileOnly } from 'react-device-detect';
const IndexCard = () => {

  return (
    <>
      <Col md={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">My Venues</h5>
              {/* <h5 className="subhead">Dashboard subhead</h5> */}
            </div>


          </CardBody>
        </Card>
      </Col>

      <Col md={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">UP Coming Events</h5>
              {/* <h5 className="subhead">Dashboard subhead</h5> */}
            </div>

          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default IndexCard;
