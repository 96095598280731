import React from 'react';
/* eslint-disable  */
import PropTypes from 'prop-types';
import {
  Route, withRouter,
} from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';
import { changeThemeToDark, changeThemeToLight } from '@/redux/actions/themeActions';
import { changeMobileSidebarVisibility, changeSidebarVisibility } from '@/redux/actions/sidebarActions';
import { SidebarProps } from '@/shared/prop-types/ReducerProps';

import Events from './Events/index';
import EventView from './Events/components/EventView';
import EventMng from './Events/components/EventMng';
import Venues from './Venues/index';
import VenueView from './Venues/components/VenueView';
import VenueMng from './Venues/components/VenueMng';
import PartySPaceMng from './Venues/components/PartySPaceMng';
import TableMng from './Venues/components/TableMng';
import Dashboard from './Dashboard/index';

const Layout = ({ dispatch, sidebar }) => {
  const layoutClass = classNames({
    layout: true,
    'layout--collapse': sidebar.collapse,
  });

  const sidebarVisibility = () => {
    dispatch(changeSidebarVisibility());
  };

  const mobileSidebarVisibility = () => {
    dispatch(changeMobileSidebarVisibility());
  };

  const changeToDark = () => {
    dispatch(changeThemeToDark());
  };

  const changeToLight = () => {
    dispatch(changeThemeToLight());
  };

  return (
    <>
      <div className={layoutClass}>
        <Topbar
          changeMobileSidebarVisibility={mobileSidebarVisibility}
          changeSidebarVisibility={sidebarVisibility}
        />
        <Sidebar
          sidebar={sidebar}
          changeToDark={changeToDark}
          changeToLight={changeToLight}
          changeMobileSidebarVisibility={mobileSidebarVisibility}
        />
      </div>
      <div className="container__wrap">
        <Route path="/events" component={Events} />
        <Route path="/eventview" component={EventView} />
        <Route path="/eventmng" component={EventMng} />
        <Route path="/venues" component={Venues} />
        <Route path="/venueview" component={VenueView} />
        <Route path="/venuemng" component={VenueMng} />
        <Route path="/mngptyspace" component={PartySPaceMng} />
        <Route path="/tablemng" component={TableMng} />
        <Route path="/dashboard" component={Dashboard} />

      </div>
    </>
  );
};

Layout.propTypes = {
  dispatch: PropTypes.func.isRequired,
  sidebar: SidebarProps.isRequired,
};

export default withRouter(connect(state => ({
  sidebar: state.sidebar,
}))(Layout));
