import React, { Component } from 'react';
/* eslint-disable  */
import { Link, withRouter } from "react-router-dom"
import axios from 'axios';
import PinWithInfoWindow from '../../../Maps/GoogleMap/components/PinWithInfoWindow';
import { CU_URL } from '../../../../config/QueryMe';
import Switch from 'react-switch';
import {
  Card, CardBody, Col, Badge, Row, Table, Button
} from 'reactstrap';
const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;
import VenueCard from '../../Venues/VenueCard';
//Lightbox
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
const initialstate = {
  itempageabout: 'Club Details',
  itemmainname: 'Club Manager',
  itemsubname: 'Club Management',
  itemsubonename: 'Club Manager',
  mitemsubonename: 'Club Management',
  itemactionname: 'Club Manager Registration',
  tname: 'cu',
  tdepname: 'dummy',
  itemvalue: '',
  userrole: '',
  name: '',
  mobile: '',
  role: 'all',

  select_mitems: [{
    value: '',
    label: (<div>
      Select Type
    </div>
    ),
  }],
  cu_user: [],
  allmitems: [],
  venue: [],
  showAlert: false,
  alertType: '',
  alertText: '',
  keyId: 0,
  keyName: '',
  oldkeyname: '',
  newkeyname: '',
  noorid: 0,

  addKeyModal: false,
  akeys: 0,
  bkeys: 0,
  tkeys: 0,
  isFits: false,
};
class UserclubView extends Component {

  state = { ...initialstate };
  constructor(props) {
    super(props)

    this.handleGetkeys = this.handleGetkeys.bind(this);
    this.handleAkey = this.handleAkey.bind(this);
    this.handleEditkey = this.handleEditkey.bind(this);
    this.handleTkey = this.handleTkey.bind(this);
    localStorage.setItem('vhsid', '');
    this.handleGetkeys();
  }

  componentDidMount() {

  }
  handleAkey = () => {
    localStorage.setItem('vhsid', '');
    localStorage.setItem('whattodovhs', 'Add Venue');
    this.props.history.push('/venuemng'); //eslint-disable-line

  };



  handleEditkey = (cuid) => {
    localStorage.setItem('cuid', cuid);
    localStorage.setItem('whattodocu', 'Edit Club User');
    this.props.history.push('/usersclubmng'); //eslint-disable-line
  };
  handleVenEditkey = (vhsid) => {
    localStorage.setItem('whattodovhs', 'Edit Venue');
    localStorage.setItem('vhsid', vhsid);
    this.props.history.push('/mngptyspace'); //eslint-disable-line
  };

  handleGetkeys = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      uid: localStorage.usercode,
      table: this.state.tname,
      deptable: this.state.tdepname,
      cuid: localStorage.cuid,
    };
    const getuurl = CU_URL + 'getcuview';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (!response.data.error) {
          localStorage.setItem("token", response.data.token);
          const cu_user = response.data.allitems;
          console.log('cu_user', response.data.cu_user);
          const venues = cu_user.cu_venues.length;

          this.setState({
            cu_user: cu_user,
            cu_venues: cu_user.cu_venues,
            venues: venues,
          });

        }
      })
      .catch((error) => {
        console.log('error', error);
        // localStorage.clear();
        //this.props.history.push('/'); //eslint-disable-line
      });
  };
  handleTkey = (id, item, checked) => {
    console.log(checked + 'checked');
    console.log(id + 'id');
    console.log(item + 'item');
    var keysts = checked == true ? 1 : 0;
    var kstatus = 'Deactivate';
    if (checked) {
      kstatus = 'Activate';
    }
    swal({
      title: kstatus + ' ' + item,
      text: 'Are you sure?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((keyconfirm) => {
      if (keyconfirm) {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: localStorage.token,
        };

        const togkeyurl = CU_URL + 'togitem';
        var postData = {
          keyid: id,
          kstatus: keysts,
          uid: localStorage.usercode,
          table: 'vhsptysp',

        };
        axios
          .post(togkeyurl, postData, {
            headers: headers,
          })
          .then((response) => {
            console.log(response.data.message);

            if (response.data.error) {
              swal(item + ' ' + response.data.message, {
                icon: 'warning',
              });
            } else {
              localStorage.setItem("token", response.data.token);
              this.handleGetkeys();
              swal(item + ' ' + response.data.message, {
                icon: 'success',
              });
            }
          })
          .catch((error) => {
            console.log('Error is ', error);
            swal('Try Again Later', {
              icon: 'Danger',
            });
          });
      }
    });
  };


  render() {
    const { cu_user, cu_venues, venues } = this.state;
    console.log('allitemsffffffffffff', this.state.cu_user);

    // console.log('this.state.cu_venues.length', venues);

    return (
      <>

        {this.state.isFits ? (
          <Lightbox
            mainSrc={this.state.lightimg}
            enableZoom={false}
            imagePadding={30}
            wrapperClassName="lightboxwrapper"
            imageCaption={
              "Caption. Can be aligned it to any side and contain any HTML."
            }
            onCloseRequest={() => this.setState({ isFits: false })}
          />
        ) : null}
        <Col xs={12} lg={12} xl={12}>
          <Card>
            <CardBody style={{ padding: '15px' }}>
              <Row>
                <Col xs={3} lg={1} style={{ paddingRight: '0px' }}>


                  <img
                    src={cu_user.cu_image_url}
                    alt={cu_user.itemname}
                    onClick={() => {
                      this.setState({
                        isFits: true,
                        lightimg: cu_user.cu_image_url
                      });
                    }}
                  />

                </Col>
                <Col xs={9} lg={6}>
                  <p className="profile__activity-name">
                    <span
                      style={{ cursor: 'pointer', float: 'left', marginRight: '10px' }}
                      onClick={this.handleEditkey.bind(
                        this,
                        cu_user.id,
                      )}
                    >
                      <i className="mdi mdi-pencil d-block font-size-16"></i>{" "}{" "}
                    </span>
                    {cu_user.itemname}
                  </p>
                  <p className="profile__activity-name">
                    <span className="profile__activity-date" style={{ fontSize: '12px', marginRight: '10px' }}> {cu_user.posted} {' '} <Badge className={cu_user.status == 1 ? 'badge badge-success' : 'badge badge-danger'} >{cu_user.status == 1 ? 'Active' : 'In Active'}</Badge></span>
                  </p>
                  <p className="bold-text">Role: {cu_user.cu_role}</p>
                  <p className="bold-text">Mobile: {cu_user.cu_mobile}</p>
                  <p className="bold-text">Email: {cu_user.cu_email}</p>
                  <p className="bold-text">Venues: {venues}</p>
                  <p className="bold-text">Add Venue: <Button
                    color="success" size="sm"
                    className="smallbtn"
                    onClick={this.handleAkey}
                  >
                    +
                  </Button></p>

                </Col>


              </Row>
            </CardBody>
          </Card>
        </Col>



        <>
          {cu_user.cu_venues &&
            cu_user.cu_venues.map((cu_venue, index) => {

              var usts = true;
              cu_venue.status = parseInt(cu_venue.status, 0);
              if (cu_venue.status === 0) {
                usts = false;
              }
              return (
                <>
                  <VenueCard key={cu_venue.id} item={cu_venue} history={this.props.history} />

                </>
              );
            })}
        </>










      </>
    );
  }
}


export default withRouter(UserclubView)
