import React, { Component } from 'react';
/* eslint-disable  */
import { withRouter } from "react-router-dom";
import Switch from 'react-switch';
import axios from 'axios';

import { VHS_URL } from '../../../../config/QueryMe';
import {
  Card, CardBody, Col, Badge, Table, Button
} from 'reactstrap';
//Lightbox
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
const initialstate = {
  itempageabout: 'Event Management',
  itemmainname: 'Event Type',
  itemsubname: 'Event Management',
  itemsubonename: 'Event',
  mitemsubonename: 'Event Type',
  itemactionname: 'Event Registration',
  tname: 'evnt',
  tdepname: 'dummy',
  itemvalue: '',
  userrole: '',
  name: '',
  mobile: '',
  role: 'all',
  // select_mitems: [
  //   { value: '', label: (<div>Select User Role</div>), },
  //   { value: 'admin', label: (<div>Admin</div>) },
  //   { value: 'coadmin', label: (<div>Co-Admin</div>) },
  //   { value: 'operator', label: (<div>Operator</div>) },
  // ],
  select_mitems: [{
    value: '',
    label: (<div>
      Select Type
    </div>
    ),
  }],
  allitems: [],
  allmitems: [],
  relateditems: [],
  showAlert: false,
  alertType: '',
  alertText: '',
  keyId: 0,
  keyName: '',
  oldkeyname: '',
  newkeyname: '',
  noorid: 0,

  addKeyModal: false,
  akeys: 0,
  bkeys: 0,
  tkeys: 0,
  isFits: false,
};
class EventList extends Component {
  constructor(props) {
    super(props)

    this.handleGetkeys = this.handleGetkeys.bind(this);

    this.handleTkey = this.handleTkey.bind(this);

    this.handleAkey = this.handleAkey.bind(this);

    this.handleViewEvnt = this.handleViewEvnt.bind(this);

    localStorage.setItem('whattodoevnt', '');
    localStorage.setItem('evntid', '');
    localStorage.setItem('event_logo', '');
    localStorage.setItem('event_building', '');
    localStorage.setItem('event_banner', '');
  }

  state = { ...initialstate };
  componentDidMount() {
    this.handleGetkeys();
  }
  handleViewEvnt = (evntid) => {

    localStorage.setItem('evntid', evntid);
    this.props.history.push('/eventview'); //eslint-disable-line
  };
  handleAkey = () => {
    localStorage.setItem('evntid', '');
    localStorage.setItem('whattodoevnt', 'Add Event');
    this.props.history.push('/eventmng'); //eslint-disable-line
  };



  handleGetkeys = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var vhsid = 0;
    if (localStorage.vhsid) {
      vhsid = localStorage.vhsid;
    }
    var postData = {
      uid: localStorage.usercode,
      vhsid: vhsid,
      table: this.state.tname,
      deptable: this.state.tdepname,
    };
    const getuurl = VHS_URL + 'getvitemsevnt';

    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (!response.data.error) {
          localStorage.setItem("token", response.data.token);
          const allitems = [...response.data.allitems];
          console.log('allitems', response.data.allitems);


          let tkeys = 0;
          allitems.map((dkey) => {
            tkeys = tkeys + 1;
          });

          this.setState({
            tkeys: tkeys,
            allitems: response.data.allitems,
            relateditems: response.data.allitems,

          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        // localStorage.clear();
        //this.props.history.push('/'); //eslint-disable-line
      });
  };

  handleTkey = (id, item, checked) => {
    console.log(checked + 'checked');
    console.log(id + 'id');
    console.log(item + 'item');
    var keysts = checked == true ? 1 : 0;
    var kstatus = 'Deactivate';
    if (checked) {
      kstatus = 'Activate';
    }
    swal({
      title: kstatus + ' ' + item,
      text: 'Are you sure?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((keyconfirm) => {
      if (keyconfirm) {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: localStorage.token,
        };

        const togkeyurl = VHS_URL + 'togitem';
        var postData = {
          keyid: id,
          kstatus: keysts,
          uid: localStorage.usercode,
          table: this.state.tname,

        };
        axios
          .post(togkeyurl, postData, {
            headers: headers,
          })
          .then((response) => {
            console.log(response.data.message);

            if (response.data.error) {
              swal(item + ' ' + response.data.message, {
                icon: 'warning',
              });
            } else {
              localStorage.setItem("token", response.data.token);
              this.handleGetkeys();
              swal(item + ' ' + response.data.message, {
                icon: 'success',
              });
            }
          })
          .catch((error) => {
            console.log('Error is ', error);
            swal('Try Again Later', {
              icon: 'Danger',
            });
          });
      }
    });
  };



  render() {
    const events = this.state.relateditems;
    return (
      <>


        <Col md={12} lg={12}>
          <Card>
            <CardBody>
              {this.state.isFits ? (
                <Lightbox
                  mainSrc={this.state.lightimg}
                  enableZoom={false}
                  imagePadding={30}
                  wrapperClassName="lightboxwrapper"
                  imageCaption={
                    "Caption. Can be aligned it to any side and contain any HTML."
                  }
                  onCloseRequest={() => this.setState({ isFits: false })}
                />
              ) : null}
              <div className="card__title">
                <Button
                  color="success" size="sm"
                  className="float-right"
                  onClick={this.handleAkey}
                >
                  +
                </Button>
                <h5 className="bold-text">{this.state.itempageabout}</h5>
                {/* <h5 className="subhead">Use default table with property <span className="red-text">responsive</span></h5> */}

              </div>
              <Table responsive className="table--bordered">
                <thead>
                  <tr>
                    <th>Event</th>
                    <th>Type</th>
                    <th>Venue</th>
                    <th>Event Date</th>
                    <th>Posted</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {events &&
                    events.map((event, index) => {

                      var usts = true;
                      event.status = parseInt(event.status, 0);
                      if (event.status === 0) {
                        usts = false;
                      }
                      return (
                        <tr key={event.id}>
                          <td>
                            <span className="float-left"
                              style={{ marginRight: '10px', cursor: 'pointer' }}
                              onClick={this.handleViewEvnt.bind(this, event.id)}
                            >
                              <i className="mdi mdi-eye font-size-16"></i>{" "}{" "}
                            </span>
                            {" "}{event.itemname}</td>
                          <td>{event.eventtype}</td>
                          <td>{event.ce_venue}</td>
                          <td>{event.event_date_from}</td>
                          <td>{event.posted}</td>
                          <td><Badge className={event.status == 1 ? 'badge badge-success' : 'badge badge-danger'} >{event.status == 1 ? 'Active' : 'In Active'}</Badge></td>
                          <td>
                            <Switch
                              height={15}
                              width={40}
                              onChange={this.handleTkey.bind(
                                this,
                                event.id,
                                event.itemname,
                              )}
                              checked={usts}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>

      </>
    );
  }
}


export default withRouter(EventList)
