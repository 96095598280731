export const AUTHENTICATE_LOGIN = 'AUTHENTICATE_LOGIN';
export const AUTHENTICATE_ERROR_AUTH = 'AUTHENTICATE_ERROR_AUTH';
export const AUTHENTICATE_ASDSTATUS_AUTH = 'AUTHENTICATE_ASDSTATUS_AUTH';
export const AUTHENTICATE_LOGOUT = 'AUTHENTICATE_LOGOUT';

export function login({
  fullName,
  avatar,
  mobile,
  email,
  userrole,
  usercode,
  upanel,
}) {
  return {
    type: AUTHENTICATE_LOGIN,
    fullName,
    avatar,
    mobile,
    email,
    userrole,
    usercode,
    upanel,
  };
}

export function authError(error) {
  return {
    type: AUTHENTICATE_ERROR_AUTH,
    error,
  };
}
export function authAsdstatus(asdstatus) {
  return {
    type: AUTHENTICATE_ASDSTATUS_AUTH,
    asdstatus,
  };
}
export function logout() {
  return { type: AUTHENTICATE_LOGOUT };
}

/* eslint-disable  */
export const handleLogout = () => (dispatch) => {
  localStorage.clear();
  sessionStorage.clear();
  dispatch(logout());
};

export const handleLogin = ({ fullName, avatar, mobile, email, userrole, usercode, upanel, token }) => (dispatch) => {
  dispatch(login({ fullName, avatar, mobile, email, userrole, usercode, upanel }));
  localStorage.setItem("usercode", usercode);
  localStorage.setItem("token", token);
  localStorage.auth = JSON.stringify({
    loggedIn: true,
    fullName,
    avatar,
    mobile,
    email,
    userrole,
    usercode,
    upanel,
    token,
  });
};

export const handleAuthError = error => (dispatch) => {
  dispatch(authError(error));
};

export const handleAuthAsdstatus = asdstatus => (dispatch) => {
  dispatch(authAsdstatus(asdstatus));
};

export const getFakeToken = id => `fake-jwt-token.${btoa(id)}`;
