import React, { Component } from 'react';
/* eslint-disable  */
import { Col, Container, Row } from 'reactstrap';
import MetaTags from 'react-meta-tags';

import ItemCard from '../ItemCard';
import axios from 'axios';
import { VHS_URL } from "../../../config/QueryMe";

const initialstate = {
  itempageabout: 'Venues',
  tname: 'vhs',
  itemtype: 'vhstype',
  item: 'venue',
  allitems: [],

};
class CVenues extends Component {
  state = { ...initialstate };

  constructor(props) {
    super(props)
    // this.state = {}
    this.handleGetkeys = this.handleGetkeys.bind(this);
  }

  componentDidMount() {
    this.handleGetkeys();
  }

  handleGetkeys = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      uid: localStorage.usercode,
      table: this.state.tname,
      itemtype: this.state.itemtype,
      item: this.state.item,
    };
    const getuurl = VHS_URL + 'getCitems';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (!response.data.error) {
          localStorage.setItem("token", response.data.token);
          const allitems = [...response.data.allitems];
          console.log('allitems', response.data.allitems);

          this.setState({
            allitems: allitems,
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        // localStorage.clear();
        //this.props.history.push('/'); //eslint-disable-line
      });
  };


  render() {
    const { allitems } = this.state;
    return (
      <Container className="dashboard">
        <MetaTags>
          <title>{this.state.itempageabout} </title>
        </MetaTags>
        <Row>
          <Col md={12}>
            <h5 className="page-title">{this.state.itempageabout}</h5>
          </Col>
        </Row>
        <Row>

          {allitems.map(item => (
            <ItemCard key={item.id} item={item} />
          ))}

        </Row>
      </Container>
    );
  }
}
export default CVenues;
