import React, { Component } from 'react';
/* eslint-disable  */
import { Alert, Row, Col, Card, CardBody, CardTitle, CardImg, CardText, CardSubtitle, Modal, Button, FormGroup, InputGroup, Label, Table } from "reactstrap";

import MetaTags from 'react-meta-tags';
import axios from 'axios';
// availity-reactstrap-validation
import { AvField, AvForm } from "availity-reactstrap-validation"
import { CU_URL, NO_IMG } from '../../../../config/QueryMe';
import CropImgUpload from '../../../CropImgUpload';
// import SweetAlert from "react-bootstrap-sweetalert"

import Select from 'react-select';
import swal from 'sweetalert';



class UsersclubMng extends Component {
  constructor(props) {
    super(props)
    this.state = {
      itempageabout: 'Add Club User | Management',
      itemlablename: 'Club Manager Name',
      tname: 'cu',
      tdepname: 'dummy',
      showAlert: false,
      alertType: '',
      alertText: '',


      select_venuetype: [{
        value: '',
        label: (<div>
          Select Venue Type
        </div>
        ),
      }],
      // select_cu_role: [{
      //   value: 'Club Manager',
      //   label: (<div>
      //     Club Manager
      //   </div>
      //   ),
      // },{
      //   value: 'Sales Manager',
      //   label: (<div>
      //     Select Venue Type
      //   </div>
      //   ),
      // },{
      //   value: 'Liquor Manager',
      //   label: (<div>
      //     Select Venue Type
      //   </div>
      //   ),
      // }],

      itemvalue: '',
      cu_mobile: '',
      cu_email: '',
      venuetypevalue: '',
      venue_name: '',
      venue_description: '',
      venue_address: '',
      hlatlong: '',
      opening_time: '',
      closing_time: '',
      timings: '',


    }
    this.handleBkey = this.handleBkey.bind(this);
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleSelectvenuetypeChange = this.handleSelectvenuetypeChange.bind(this);

  }

  componentDidMount() {
    this.handleGetkeys();
  }

  handleBkey = () => {
    localStorage.setItem('cuid', '');
    this.props.history.push('/usersclub'); //eslint-disable-line
  };

  handleGetkeys = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };

    var postData = {
      uid: localStorage.usercode,
      table: this.state.tname,
      cuid: localStorage.cuid,
    };
    const getuurl = CU_URL + 'getcudetails';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (!response.data.error) {
          localStorage.setItem("token", response.data.token);
          var cudata = response.data.allitems;
          const allvenuetypes = [...response.data.venuetypes];

          if (localStorage.cuid) {

            this.setState({
              editmode: true,
              editid: cudata.id,
              itemvalue: cudata.itemname,
              cu_mobile: cudata.cu_mobile,
              cu_email: cudata.cu_email,
              cu_image_url: cudata.cu_image_url,
              showAlert: false,
              select_venuetype: allvenuetypes,

            });
          } else {
            this.setState({
              editmode: false,
              select_venuetype: allvenuetypes,
            });
          }


        }
      })
      .catch((error) => {
        console.log('error', error);
        // localStorage.clear();
        //this.props.history.push('/venues'); //eslint-disable-line
      });
  };

  handleValidSubmit = (event, values) => {
    event.preventDefault();
    console.log('values', values);
    var noerrors = false;
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var addkeyurl = CU_URL + 'additemcu';
    if (this.state.editmode) {

      if (!localStorage.cu_image_url) {
        swal("Select Profile Picture", {
          icon: 'warning',
        });
      } else {
        noerrors = true;
      }
    } else {
      if (!localStorage.venue_logo) {
        swal("Select Venue logo", {
          icon: 'warning',
        });
      } else if (!localStorage.cu_image_url) {
        swal("Select Profile Picture", {
          icon: 'warning',
        });
      } else if (!localStorage.venue_building) {
        swal("Select Venue building", {
          icon: 'warning',
        });
      } else if (!localStorage.venue_banner) {
        swal("Select Venue banner", {
          icon: 'warning',
        });
      } else if (!this.state.venuetypevalue) {
        swal("Select Venue Type", {
          icon: 'warning',
        });
      } else {
        noerrors = true;
      }

    }



    if (noerrors) {

      if (this.state.editmode) {
        addkeyurl = CU_URL + 'edititemcu';
        postData = {
          itemid: this.state.editid,
          item: values.item,
          uid: localStorage.usercode,
          table: this.state.tname,
          venue_name: values.venue_name,
          cu_mobile: values.cu_mobile,
          cu_email: values.cu_email,
          cu_image_url: localStorage.cu_image_url,

        };
      } else {
        var postData = {
          item: values.item,
          uid: localStorage.usercode,
          table: this.state.tname,
          venuetype: this.state.venuetypevalue,
          venue_name: values.venue_name,
          cu_mobile: values.cu_mobile,
          cu_email: values.cu_email,
          venue_description: values.venue_description,
          venue_address: values.venue_address,
          hlatlong: values.hlatlong,
          opening_time: values.opening_time,
          closing_time: values.closing_time,
          timings: values.timings,
          cu_image_url: localStorage.cu_image_url,
          venue_logo: localStorage.venue_logo,
          venue_building: localStorage.venue_building,
          venue_banner: localStorage.venue_banner,
        };
      }


      axios
        .post(addkeyurl, postData, {
          headers: headers,
        })
        .then((response) => {
          console.log(response.data.message);
          if (response.data.error) {
            this.setState({
              showAlert: true,
              alertType: 'warning',
              alertText: response.data.message,
            });
          } else {
            this.setState({
              showAlert: false,
              alertType: 'warning',
              alertText: '',
            });
            localStorage.setItem("token", response.data.token);


            swal(values.item + ' ' + response.data.message, {
              icon: 'success',
            });
            this.props.history.push('/usersclub'); //eslint-disable-line
          }
        })
        .catch((error) => {
          console.log('Error is ', error);
          this.setState({
            alertType: 'danger',
            showAlert: true,
            alertText: 'Failed Try Again Later!',
          });
        });
    }
  };

  handleSelectvenuetypeChange = (selectedOption) => {
    console.log('selectedOption', selectedOption);
    let venuetypevalue = selectedOption.value;
    this.setState({ venuetypevalue: venuetypevalue, venuetypevaluename: selectedOption.label, });
  };




  render() {

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>{localStorage.whattodocu} </title>
          </MetaTags>
          <div className="container-fluid">


            <Row>
              <Col className="col-12">


                <Card>
                  <div className="card__title">
                    <Button
                      color="success" size="sm"
                      className="float-right"
                      onClick={this.handleBkey}
                    >
                      Back to Venues
                    </Button>
                    <h5 className="bold-text">{this.state.itempageabout}</h5>
                    {/* <h5 className="subhead">Use default table with property <span className="red-text">responsive</span></h5> */}

                  </div>
                  <CardBody>
                    {this.state.editmode ? (
                      <Row>
                        <Col md={4}>
                          <div className="mb-3">
                            <p>Profile Picture</p>
                            <Row>
                              <Col md={6}>
                                <CropImgUpload imgsrc={localStorage.cu_image_url} className="img-thumbnail onclicklink" onwhich='cu_image_url' imgtitle='Profile Picture' imgcropw={1} imgcroph={1} imgw={250} imgh={250} />
                              </Col>
                              <Col md={6}>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col md={3}>
                          <div className="mb-3">
                            <p>Profile Picture</p>
                            <Row>
                              <Col md={6}>
                                <CropImgUpload imgsrc={localStorage.cu_image_url} className="img-thumbnail onclicklink" onwhich='cu_image_url' imgtitle='Profile Picture' imgcropw={1} imgcroph={1} imgw={250} imgh={250} />
                              </Col>
                              <Col md={6}>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                        <Col md={3}>
                          <div className="mb-3">
                            <p>Logo</p>
                            <Row>
                              <Col md={6}>
                                <CropImgUpload imgsrc={localStorage.venue_logo} className="img-thumbnail onclicklink" onwhich='venue_logo' imgtitle='Venue Logo Image' imgcropw={1} imgcroph={1} imgw={250} imgh={250} />
                              </Col>
                              <Col md={6}>
                              </Col>
                            </Row>
                          </div>
                        </Col>

                        <Col md={3}>
                          <p>Venue Building</p>
                          <div className="mb-3">
                            <Row>
                              <Col md={6}>
                                <CropImgUpload imgsrc={localStorage.venue_building} className="img-thumbnail onclicklink" onwhich='venue_building' imgtitle='Venue Building Image' imgcropw={1} imgcroph={1} imgw={250} imgh={250} />
                              </Col>
                              <Col md={6}>
                              </Col>
                            </Row>
                          </div>
                        </Col>

                        <Col md={3}>
                          <p>Venue Banner</p>
                          <div className="mb-3">
                            <Row>
                              <Col md={6}>
                                <CropImgUpload imgsrc={localStorage.venue_banner} className="img-thumbnail onclicklink" onwhich='venue_banner' imgtitle='Venue Banner Image' imgcropw={2} imgcroph={1} imgw={0} imgh={300} />
                              </Col>
                              <Col md={6}>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    )}

                    <AvForm
                      className="needs-validation"
                      onValidSubmit={this.handleValidSubmit}
                    >
                      {this.state.showAlert ? (
                        <Alert color={this.state.alertType}>
                          <p>{this.state.alertText}</p>
                        </Alert>
                      ) : null}


                      <Row>

                        <Col md={4}>
                          <div className="mb-3 mandate">
                            <AvField
                              name="item"
                              label={this.state.itemlablename}
                              value={this.state.itemvalue}
                              type="text"
                              required
                              placeholder={'Enter ' + this.state.itemlablename}
                            />
                          </div>
                        </Col>



                        <Col md={4}>
                          <div className="mb-3 mandate">
                            <AvField
                              name="cu_mobile"
                              label='Contact Person Mobile'
                              value={this.state.cu_mobile}
                              type="number"
                              placeholder={'Enter Mobile'}
                            />
                          </div>
                        </Col>


                        <Col md={4}>
                          <div className="mb-3 mandate">
                            <AvField
                              name="cu_email"
                              label='Contact Person Email'
                              value={this.state.cu_email}
                              type="email"
                              placeholder={'Enter Email'}
                            />
                          </div>
                        </Col>

                        <Col md={4}>
                          <div className="mb-3 mandate">
                            <AvField
                              name="venue_name"
                              label='Venue Name'
                              value={this.state.venue_name}
                              type="text"
                              placeholder={'Enter Venue Name'}
                            />
                          </div>
                        </Col>




                        <Col md={4}>
                          <div className="mb-3">
                            <div className="form-group mandate">
                              <label className="">Venue Type</label>
                              <Select
                                name="venuetypevalue"
                                value={this.state.select_venuetype.filter(
                                  (option) => option.value == this.state.venuetypevalue,
                                )}
                                onChange={this.handleSelectvenuetypeChange}
                                options={this.state.select_venuetype}
                                clearable={false}
                                className="react-select-team"
                                classNamePrefix="react-select"
                              />
                            </div>
                          </div>
                        </Col>

                        <Col md={4}>
                          <div className="mb-3 mandate">
                            <AvField
                              name="hlatlong"
                              label='Latitude Longitude (Copy from google Map)'
                              value={this.state.hlatlong}
                              type="text"
                              required
                              placeholder={'Enter Latitude,Longitude'}
                            />
                          </div>
                        </Col>



                        <Col md={3}>
                          <div className="mb-3">
                            <AvField
                              name="opening_time"
                              label='Opening Time'
                              value={this.state.opening_time}
                              type="text"

                              placeholder={'Enter Opening Time'}
                            />
                          </div>
                        </Col>

                        <Col md={3}>
                          <div className="mb-3">
                            <AvField
                              name="closing_time"
                              label='Closing Time'
                              value={this.state.closing_time}
                              type="text"

                              placeholder={'Enter Closing Time'}
                            />
                          </div>
                        </Col>

                        <Col md={6}>
                          <div className="mb-3 mandate">
                            <AvField
                              name="timings"
                              label='Venue Timings'
                              value={this.state.timings}
                              type="text"
                              required
                              placeholder={'Enter Timings'}
                            />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3">
                            <AvField name="venue_address" label='Address' value={this.state.venue_address} type="text" required placeholder={'Enter  Venue Address'} />
                          </div>
                        </Col>
                        <Col md={12}>
                          <div className="mb-3 mandate">
                            <AvField name="venue_description" label='Venue Description' value={this.state.venue_description} type="textarea" required placeholder={'Enter  Venue Description'} />
                          </div>
                        </Col>

                      </Row>
                      <div className="mt-4 d-grid">
                        <button
                          className="btn btn-primary float-right waves-effect waves-light"
                          type="submit"
                        >
                          {localStorage.whattodocu}
                        </button>
                      </div>

                    </AvForm>

                  </CardBody>
                </Card>
              </Col>
            </Row>

          </div>

        </div>
      </React.Fragment>
    )
  }
}

export default UsersclubMng
