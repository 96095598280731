import React, { useState } from 'react';
/* eslint-disable  */
import { useHistory } from "react-router-dom"
import { Col, Card, CardBody, Row, Collapse, Button, Badge } from 'reactstrap';
import PinWithInfoWindowcust from '../../Maps/GoogleMap/components/PinWithInfoWindowcust';
import MinusIcon from 'mdi-react/MinusIcon';
import MapMarkerIcon from 'mdi-react/MapMarkerIcon';
import FileIcon from 'mdi-react/FileIcon';
import StarsIcon from 'mdi-react/StarsIcon';
import { VHS_URL } from '../../../config/QueryMe';
import Switch from 'react-switch';
import axios from 'axios';

const VenueCard = ({ item }) => {
  var usts = true;
  item.status = parseInt(item.status, 0);
  if (item.status === 0) {
    usts = false;
  }
  const [itemstatus, setItemstatus] = useState(usts);
  const [showmap, setShowmap] = useState(false);
  const [showdes, setShowdes] = useState(false);
  const [showsps, setShowsps] = useState(true);
  const togglemap = () => {
    setShowmap(prevState => !prevState);
    setShowdes(false);
    setShowsps(false);
  };
  const toggledes = () => {
    setShowsps(false);
    setShowmap(false);
    setShowdes(prevState => !prevState);
  };

  const togglesps = () => {
    setShowdes(false);
    setShowmap(false);
    setShowsps(prevState => !prevState);
  };

  const [mapview, setMapview] = useState({ styleClass: 'closed', mapicon: <MapMarkerIcon color="#c2212e" /> });
  const onShowmaping = () => {
    setMapview({ styleClass: 'opening', mapicon: <MinusIcon /> });
  };

  const onShowmaped = () => {
    setMapview({ styleClass: 'opened', mapicon: <MinusIcon /> });
  };

  const onShowmapExiting = () => {
    setMapview({ styleClass: 'closing', mapicon: <MapMarkerIcon /> });
  };

  const onShowmapExited = () => {
    setMapview({ styleClass: 'closed', mapicon: <MapMarkerIcon color="#c2212e" /> });
  };

  const [desview, setDesview] = useState({ styleClass: 'closed', desicon: <FileIcon color="#c2212e" /> });
  const onShowdesing = () => {
    setDesview({ styleClass: 'opening', desicon: <MinusIcon /> });
  };

  const onShowdesed = () => {
    setDesview({ styleClass: 'opened', desicon: <MinusIcon /> });
  };

  const onShowdesExiting = () => {
    setDesview({ styleClass: 'closing', desicon: <FileIcon /> });
  };

  const onShowdesExited = () => {
    setDesview({ styleClass: 'closed', desicon: <FileIcon color="#c2212e" /> });
  };

  const [spsview, setSpsview] = useState({ styleClass: 'closed', spsicon: <StarsIcon color="#c2212e" /> });

  const onShowspsing = () => {
    setSpsview({ styleClass: 'opening', spsicon: <MinusIcon /> });
  };

  const onShowspsed = () => {
    setSpsview({ styleClass: 'opened', spsicon: <MinusIcon /> });
  };

  const onShowspsExiting = () => {
    setSpsview({ styleClass: 'closing', spsicon: <StarsIcon /> });
  };

  const onShowspsExited = () => {
    setSpsview({ styleClass: 'closed', spsicon: <StarsIcon color="#c2212e" /> });
  };


  const handleTkey = (checked) => {
    console.log(checked + 'checked');

    var keysts = checked == true ? 1 : 0;
    var kstatus = 'Deactivate';
    if (checked) {
      kstatus = 'Activate';
    }
    swal({
      title: kstatus + ' ' + item.title,
      text: 'Are you sure?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((keyconfirm) => {
      if (keyconfirm) {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: localStorage.token,
        };

        const togkeyurl = VHS_URL + 'togitem';
        var postData = {
          keyid: item.id,
          kstatus: keysts,
          uid: localStorage.usercode,
          table: 'vhs',

        };
        axios
          .post(togkeyurl, postData, {
            headers: headers,
          })
          .then((response) => {
            console.log(response.data.message);

            if (response.data.error) {
              swal(item.title + ' ' + response.data.message, {
                icon: 'warning',
              });
            } else {
              localStorage.setItem("token", response.data.token);
              setItemstatus(checked);
              swal(item.title + ' ' + response.data.message, {
                icon: 'success',
              });
            }
          })
          .catch((error) => {
            console.log('Error is ', error);
            swal('Try Again Later', {
              icon: 'Danger',
            });
          });
      }
    });
  };
  let history = useHistory();
  const handleAddEvnt = (sps) => {
    console.log('sps', sps);
    localStorage.setItem('spsid', sps);
    localStorage.setItem('vhsid', item.id);
    history.push('/eventmng'); //eslint-disable-line
  };
  const handleViewVhs = () => {

    localStorage.setItem('vhsid', item.id);
    history.push('/venueview'); //eslint-disable-line
  };

  const handleViewEvnt = () => {

    localStorage.setItem('vhsid', item.id);
    history.push('/events'); //eslint-disable-line
  };

  const hlat = parseFloat(item.hlat);
  const hlong = parseFloat(item.hlong);
  return (



    <Col md={4} style={{ marginBottom: '20px' }}>

      <Card>
        <CardBody style={{ padding: '10px' }}>
          <Row>
            <Col md={3} style={{ marginBottom: '10px', paddingRight: '0px' }}>
              {item.logo ? <img className="profile__activity-avatar" style={{ marginRight: '0px' }} src={item.logo} alt="Logo" /> : ''}
            </Col>
            <Col md={9} style={{ marginBottom: '10px' }}>
              <div className="card__title" style={{ marginTop: '0px', marginBottom: '15px' }}>
                <h5 className="bold-text">
                  <span className="float-left"
                    style={{ marginRight: '10px', cursor: 'pointer' }}
                    onClick={() => handleViewVhs()}
                  >
                    <i className="mdi mdi-eye font-size-16"></i>{" "}{" "}
                  </span>
                  {" "}
                  {item.title}</h5>
                <h5 className="subhead">
                  {item.itemtype}
                  <span style={{ float: 'right' }}>{item.timings}</span></h5>
              </div>
            </Col>
            <Col xs={4} style={{ marginBottom: '10px' }}> <Switch
              height={15}
              width={40}
              onChange={handleTkey}

              checked={itemstatus}
            /></Col>
            <Col xs={4} style={{ marginBottom: '10px' }}>

            </Col>
            <Col xs={4} style={{ marginBottom: '10px' }}>
              <Button
                color="success" size="sm"
                onClick={() => handleViewEvnt()}
              >
                View Events
              </Button>
            </Col>

          </Row>
          <div className="catalog-item__img-wrap">
            <img src={item.building} alt="Building" />
          </div>


          <div className="profile__stats">
            <div className="profile__stat" onClick={toggledes}>
              <p className="profile__stat-number">{desview.desicon}</p>
              <p className="profile__stat-title">About</p>
            </div>
            <div className="profile__stat" onClick={togglesps}>
              <p className="profile__stat-number">{spsview.spsicon}</p>
              <p className="profile__stat-title">Spaces</p>
            </div>
            <div className="profile__stat" onClick={togglemap}>
              <p className="profile__stat-number">{mapview.mapicon}</p>
              <p className="profile__stat-title">Map</p>
            </div>
          </div>



          <Collapse
            isOpen={showmap}
            className="collapse__content"
            onEntering={onShowmaping}
            onEntered={onShowmaped}
            onExiting={onShowmapExiting}
            onExited={onShowmapExited}
          >
            <>

              <PinWithInfoWindowcust hname={item.title} haddress={item.address} vlat={hlat} vlong={hlong} />
            </>
          </Collapse>
          <Collapse
            isOpen={showdes}
            className="collapse__content"
            onEntering={onShowdesing}
            onEntered={onShowdesed}
            onExiting={onShowdesExiting}
            onExited={onShowdesExited}
          >
            <>
              {item.opening_time ? (
                <p className="profile__stat-title" style={{ marginBottom: '5px' }}><b>Opening-Closing: </b>{item.opening_time}-{item.closing_time}</p>
              ) : ''}

              <h5 className="bold-text" style={{ marginBottom: '5px' }}>About Venue</h5>
              {item.banner ? <img src={item.banner} alt="Banner" /> : ''}
              {item.description}
            </>
          </Collapse>
          <Collapse
            isOpen={showsps}
            className="collapse__content"
            onEntering={onShowspsing}
            onEntered={onShowspsed}
            onExiting={onShowspsExiting}
            onExited={onShowspsExited}
          >
            <>
              {item.party_spaces &&
                item.party_spaces.map((party_space, index) => {


                  return (

                    <div className="venuespsbox">
                      <div className="card__title">

                        <h5 className="bold-text">{party_space.party_space}<Badge className={party_space.status == 1 ? 'float-right badge badge-success' : 'float-right badge badge-danger'} >{party_space.status == 1 ? 'Active' : 'In Active'} </Badge></h5>
                        <h5 className="subhead">Capacity <span className="red-text">{party_space.capacity}</span>
                          <Button
                            color="success" size="sm"
                            className="float-right"
                            onClick={() => handleAddEvnt(party_space.id)}
                          >
                            + Event
                          </Button>
                        </h5>
                      </div>



                      {/* <img
                        src={party_space.diagram_1}
                        alt={party_space.party_space}
                        onClick={() => {
                          this.setState({
                            isFits: true,
                            lightimg: party_space.diagram_1
                          });
                        }}
                        className="img-fluid img-thumbnail"
                      />


                      <img
                        src={party_space.diagram_2}
                        alt={party_space.party_space}
                        onClick={() => {
                          this.setState({
                            isFits: true,
                            lightimg: party_space.diagram_2
                          });
                        }}
                        className="img-fluid img-thumbnail"
                      />


                      <img
                        src={party_space.diagram_3}
                        alt={party_space.party_space}
                        onClick={() => {
                          this.setState({
                            isFits: true,
                            lightimg: party_space.diagram_3
                          });
                        }}
                        className="img-fluid img-thumbnail"
                      />

                      <img
                        src={party_space.diagram_4}
                        alt={party_space.party_space}
                        onClick={() => {
                          this.setState({
                            isFits: true,
                            lightimg: party_space.diagram_4
                          });
                        }}
                        className="img-fluid img-thumbnail"
                      />

                      <img
                        src={party_space.diagram_5}
                        alt={party_space.party_space}
                        onClick={() => {
                          this.setState({
                            isFits: true,
                            lightimg: party_space.diagram_5
                          });
                        }}
                        className="img-fluid img-thumbnail"
                      /> */}



                    </div>

                  );
                })}
            </>
          </Collapse>
        </CardBody>
      </Card >


    </Col >


  );
};



VenueCard.defaultProps = {
  items: [],
};

export default VenueCard;
