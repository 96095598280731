import React from 'react';
/* eslint-disable  */
import { Col, Container, Row } from 'reactstrap';
import IndexCard from './components/IndexCard';

const Dashboard = () => (
  <Container className="dashboard">
    {/* <Row>
      <Col md={12}>
        <h3 className="page-title">Dashboard</h3>
      </Col>
    </Row> */}
    <Row>
      <IndexCard />
    </Row>
  </Container>
);

export default Dashboard;
