import React, { Component } from 'react';
/* eslint-disable  */
import { Link, withRouter } from "react-router-dom"
import axios from 'axios';
import PinWithInfoWindow from '../../../Maps/GoogleMap/components/PinWithInfoWindow';
import { VHS_URL } from '../../../../config/QueryMe';
import Switch from 'react-switch';
import {
  Card, CardBody, Col, Badge, Row, Table, Button
} from 'reactstrap';
import { format } from 'date-fns';

//Lightbox
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
const initialstate = {
  itempageabout: 'Event Management',
  itemmainname: 'Event Type',
  itemsubname: 'Event Management',
  itemsubonename: 'Event',
  mitemsubonename: 'Event Type',
  itemactionname: 'Event Registration',
  tname: 'evnt',
  tdepname: 'dummy',
  itemvalue: '',
  userrole: '',
  name: '',
  mobile: '',
  role: 'all',

  select_mitems: [{
    value: '',
    label: (<div>
      Select Type
    </div>
    ),
  }],
  allitems: [],
  allmitems: [],
  event: [],
  showAlert: false,
  alertType: '',
  alertText: '',
  keyId: 0,
  keyName: '',
  oldkeyname: '',
  newkeyname: '',
  noorid: 0,

  addKeyModal: false,
  akeys: 0,
  bkeys: 0,
  tkeys: 0,
  isFits: false,
};
class EventView extends Component {

  state = { ...initialstate };
  constructor(props) {
    super(props)

    this.handleGetkeys = this.handleGetkeys.bind(this);
    this.handleEditkey = this.handleEditkey.bind(this);
    this.handleTkey = this.handleTkey.bind(this);
    localStorage.setItem('event_thumbnail_image', '');
    // localStorage.setItem('event_flyer', '');
    localStorage.setItem('event_cover_image', '');
    this.handleGetkeys();
  }

  componentDidMount() {

  }

  handleBack = () => {
    this.props.history.push('/events'); //eslint-disable-line
  };

  handleEditkey = (evntid) => {
    localStorage.setItem('whattodoevnt', 'Edit Event');

    this.props.history.push('/eventmng'); //eslint-disable-line
  };


  handleGetkeys = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      uid: localStorage.usercode,
      table: this.state.tname,
      deptable: this.state.tdepname,
      evntid: localStorage.evntid,
    };
    const getuurl = VHS_URL + 'getitemsevntone';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (!response.data.error) {
          localStorage.setItem("token", response.data.token);
          const allitems = response.data.allitems;
          console.log('allitems', response.data.allitems);
          if (allitems) {
            let hospi = allitems.items;
            const latlong = hospi.hlatlong.split(",");
            const latt = parseFloat(latlong[0]);
            const longg = parseFloat(latlong[1]);

            let event_time_fromd = new Date(allitems.items.event_time_from);
            let event_date_fromd = new Date(allitems.items.event_date_from);
            let event_time_from = format(event_time_fromd, 'h:mm aa');
            let event_date_from = format(event_date_fromd, 'dd-MM-yyyy');
            this.setState({
              allitems: allitems,
              event: allitems.items,
              event_time_from: event_time_from,
              event_date_from: event_date_from,
              hlat: latt,
              hlong: longg,

            }, () => {

              localStorage.setItem('event_thumbnail_image', hospi.event_thumbnail_image);
              // localStorage.setItem('event_flyer', hospi.event_flyer);
              localStorage.setItem('event_cover_image', hospi.event_cover_image);
            });
          }
        }
      })
      .catch((error) => {
        console.log('error', error);
        // localStorage.clear();
        //this.props.history.push('/'); //eslint-disable-line
      });
  };
  handleTkey = (id, item, checked) => {
    console.log(checked + 'checked');
    console.log(id + 'id');
    console.log(item + 'item');
    var keysts = checked == true ? 1 : 0;
    var kstatus = 'Deactivate';
    if (checked) {
      kstatus = 'Activate';
    }
    swal({
      title: kstatus + ' ' + item,
      text: 'Are you sure?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((keyconfirm) => {
      if (keyconfirm) {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: localStorage.token,
        };

        const togkeyurl = VHS_URL + 'togitem';
        var postData = {
          keyid: id,
          kstatus: keysts,
          uid: localStorage.usercode,
          table: 'vhsptysp',

        };
        axios
          .post(togkeyurl, postData, {
            headers: headers,
          })
          .then((response) => {
            console.log(response.data.message);

            if (response.data.error) {
              swal(item + ' ' + response.data.message, {
                icon: 'warning',
              });
            } else {
              localStorage.setItem("token", response.data.token);
              this.handleGetkeys();
              swal(item + ' ' + response.data.message, {
                icon: 'success',
              });
            }
          })
          .catch((error) => {
            console.log('Error is ', error);
            swal('Try Again Later', {
              icon: 'Danger',
            });
          });
      }
    });
  };


  render() {
    const { event, allitems } = this.state;
    // console.log('venue', allitems.party_spaces);



    return (
      <>

        {this.state.isFits ? (
          <Lightbox
            mainSrc={this.state.lightimg}
            enableZoom={false}
            imagePadding={30}
            wrapperClassName="lightboxwrapper"
            imageCaption={
              "Caption. Can be aligned it to any side and contain any HTML."
            }
            onCloseRequest={() => this.setState({ isFits: false })}
          />
        ) : null}
        <Col xs={12} lg={12} xl={12} style={{ padding: '5px' }}>
          <Card>
            <CardBody style={{ padding: '5px' }}>
              <div className="card__title">
                {/* <Button
                  color="success" size="sm"
                  className="float-right"
                  onClick={this.handleBack}
                >
                  Back to Events
                </Button> */}
                <img
                  src={allitems.event_thumbnail_image}
                  alt={allitems.itemname}
                  onClick={() => {
                    this.setState({
                      isFits: true,
                      lightimg: allitems.event_thumbnail_image
                    });
                  }}
                  className="profile_thumbnail_image img-fluid img-thumbnail"
                />
                <h5 className="bold-text"> <span
                  style={{ cursor: 'pointer', float: 'left', marginRight: '10px' }}
                  onClick={this.handleEditkey.bind(
                    this,
                    allitems.id,
                  )}
                >
                  <i className="mdi mdi-pencil d-block font-size-16"></i>{" "}{" "}
                </span>{allitems.itemname}</h5>
                <h5 className="subhead"><b>Type:</b> {allitems.eventtype}</h5>
                <h5 className="subhead"><b>Timing:</b> {allitems.timings}</h5>
                <br />
              </div>



              {/* <Col xs={6} lg={1}>
                  <p className="bold-text">Event Flyer </p>
                  <img
                    src={event.event_flyer}
                    alt={allitems.itemname}
                    onClick={() => {
                      this.setState({
                        isFits: true,
                        lightimg: event.event_flyer
                      });
                    }}
                    className="img-fluid img-thumbnail"
                  />
                </Col> */}
              <Col xs={12} lg={12}>
                <p className="bold-text">Event Banner</p>
                <img
                  src={event.event_cover_image}
                  alt={allitems.itemname}
                  onClick={() => {
                    this.setState({
                      isFits: true,
                      lightimg: event.event_cover_image
                    });
                  }}
                  className="img-fluid img-thumbnail"
                />
              </Col>

            </CardBody>
          </Card>
        </Col>
        <Row>
          <Col xs={12} lg={6}>
            <Card>
              <CardBody style={{ padding: '15px' }}>
                <Table responsive striped>
                  <tbody>
                    <tr>
                      <th colSpan={2}>Event Details</th>
                    </tr>
                    <tr>
                      <th scope="row"> Date: </th>
                      <td>{this.state.event_date_from}</td>
                    </tr>
                    <tr>
                      <th scope="row"> Time: </th>
                      <td>{this.state.event_time_from}</td>
                    </tr>
                    <tr>
                      <td colSpan={2}><b>Description: </b> <br />{event.event_description}</td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} lg={6}>
            <Card>
              <CardBody style={{ padding: '15px' }}>
                <Table responsive striped>
                  <tbody>
                    <tr>
                      <th colSpan={2}>Event Contact Details</th>
                    </tr>
                    <tr>
                      <th scope="row"> Host: </th>
                      <td>{event.event_mng_orgname}</td>
                    </tr>
                    <tr>
                      <th scope="row"> Name: </th>
                      <td>{event.event_mng_name}</td>
                    </tr>
                    <tr>
                      <th scope="row"> Designation: </th>
                      <td>{event.event_mng_designation}</td>
                    </tr>
                    <tr>
                      <th scope="row"> Mobile: </th>
                      <td>{event.event_mng_mobile}</td>
                    </tr>
                    <tr>
                      <th scope="row"> Email: </th>
                      <td>{event.event_mng_email}</td>
                    </tr>


                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
          <Col xs={12} lg={6}>
            <Card>
              <CardBody style={{ padding: '15px' }}>
                <Table striped>
                  <tbody>
                    <tr>
                      <th colSpan={2}>Venue: {event.ce_venue}</th>
                    </tr>
                    <tr>
                      <td colSpan={2}><img
                        src={event.venue_building}
                        alt={allitems.itemname}
                        onClick={() => {
                          this.setState({
                            isFits: true,
                            lightimg: event.venue_building
                          });
                        }}
                        className="img-fluid img-thumbnail "
                      /></td>
                    </tr>
                    <tr>
                      <th colSpan={2}>Space: {event.party_space}</th>
                    </tr>
                    <tr>
                      <td colSpan={2}><b>Capacity: </b>{event.capacity}</td>
                    </tr>
                    <tr>
                      <td colSpan={2}><b>Space Details: </b>{event.party_space_description}</td>
                    </tr>
                    <tr>
                      <td colSpan={2}>
                        <img
                          src={event.diagram_1}
                          alt={event.party_space}
                          onClick={() => {
                            this.setState({
                              isFits: true,
                              lightimg: event.diagram_1
                            });
                          }}
                          className="img-fluid img-thumbnail"
                        />


                        <img
                          src={event.diagram_2}
                          alt={event.party_space}
                          onClick={() => {
                            this.setState({
                              isFits: true,
                              lightimg: event.diagram_2
                            });
                          }}
                          className="img-fluid img-thumbnail"
                        />


                        <img
                          src={event.diagram_3}
                          alt={event.party_space}
                          onClick={() => {
                            this.setState({
                              isFits: true,
                              lightimg: event.diagram_3
                            });
                          }}
                          className="img-fluid img-thumbnail"
                        />

                        <img
                          src={event.diagram_4}
                          alt={event.party_space}
                          onClick={() => {
                            this.setState({
                              isFits: true,
                              lightimg: event.diagram_4
                            });
                          }}
                          className="img-fluid img-thumbnail"
                        />

                        <img
                          src={event.diagram_5}
                          alt={event.party_space}
                          onClick={() => {
                            this.setState({
                              isFits: true,
                              lightimg: event.diagram_5
                            });
                          }}
                          className="img-fluid img-thumbnail"
                        />
                      </td>
                    </tr>


                    <tr>
                      <td colSpan={2}><b>Address: </b>{event.venue_address}</td>
                    </tr>

                  </tbody>
                </Table>
              </CardBody>
            </Card>

          </Col>

          <PinWithInfoWindow hname={event.ce_venue} haddress={event.venue_address} vlat={this.state.hlat} vlong={this.state.hlong} />

          <Col md={12} lg={6}>
            <Card>
              <CardBody style={{ padding: '15px' }}>
                <div className="card__title">
                  <h5 className="bold-text">About {event.ce_venue}</h5>
                </div>
                {event.venue_description}




              </CardBody>
            </Card>

          </Col>


        </Row>




      </>
    );
  }
}


export default withRouter(EventView)
