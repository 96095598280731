/* eslint-disable  */
import {
  AUTHENTICATE_LOGIN,
  AUTHENTICATE_ERROR_AUTH,
  AUTHENTICATE_ASDSTATUS_AUTH,
  AUTHENTICATE_LOGOUT,
} from '../actions/authActions';

const authDefaults = {
  loggedIn: false,
  fullName: '',
  avatar: '',
  mobile: '',
  email: '',
  userrole: '',
  usercode: '',
  upanel: '',
  error: '',
  asdstatus: 'userbox',
};

const storedState = localStorage.auth ? JSON.parse(localStorage.auth) : {};

const initialState = {
  loggedIn: storedState.loggedIn || authDefaults.loggedIn,
  fullName: storedState.fullName || authDefaults.fullName,
  avatar: storedState.avatar || authDefaults.avatar,
  mobile: storedState.mobile || authDefaults.mobile,
  email: storedState.email || authDefaults.email,
  userrole: storedState.userrole || authDefaults.userrole,
  usercode: storedState.usercode || authDefaults.usercode,
  upanel: storedState.upanel || authDefaults.upanel,
  error: authDefaults.error,
  asdstatus: authDefaults.asdstatus,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTHENTICATE_LOGIN:
      return {
        ...state,
        loggedIn: true,
        fullName: action.fullName,
        avatar: action.avatar,
        mobile: action.mobile,
        email: action.email,
        userrole: action.userrole,
        usercode: action.usercode,
        upanel: action.upanel,
      };
    case AUTHENTICATE_ERROR_AUTH:
      return { ...state, error: action.error };
    case AUTHENTICATE_ASDSTATUS_AUTH:
      return { ...state, asdstatus: action.asdstatus };
    case AUTHENTICATE_LOGOUT:
      return authDefaults;
    default:
      return state;
  }
};

export default authReducer;
