import React, { Component } from 'react';
/* eslint-disable  */
import { Link, withRouter } from "react-router-dom"
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import axios from 'axios';
import { AvField, AvForm } from 'availity-reactstrap-validation';

import { VHS_URL } from '../../../../config/QueryMe';
import {
  Card, CardBody, Col, Badge, Table, Row, Button,
} from 'reactstrap';
import VenueCard from '../VenueCard';
//Lightbox
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
const initialstate = {
  itempageabout: 'Venue Management',
  tname: 'vhs',
  itemtype: 'vhstype',
  item: 'venue',
  allitems: [],

  showAlert: false,
  alertType: '',
  alertText: '',
  keyId: 0,
  keyName: '',
  oldkeyname: '',
  newkeyname: '',
  noorid: 0,

  addKeyModal: false,
  akeys: 0,
  bkeys: 0,
  tkeys: 0,
  isFits: false,
};
class VenueList extends Component {
  constructor(props) {
    super(props)

    this.handleGetkeys = this.handleGetkeys.bind(this);

    this.handleTkey = this.handleTkey.bind(this);

    this.handleAkey = this.handleAkey.bind(this);

    localStorage.setItem('whattodovhs', 'Add Venue');
    localStorage.setItem('vhsid', '');
    localStorage.setItem('spsid', '');

    localStorage.setItem('venue_logo', '');
    localStorage.setItem('venue_building', '');
    localStorage.setItem('venue_banner', '');

    localStorage.setItem('whattodoevnt', 'Add Event');
    localStorage.setItem('evntid', '');
    localStorage.setItem('event_logo', '');
    localStorage.setItem('event_building', '');
    localStorage.setItem('event_banner', '');
  }

  state = { ...initialstate };
  componentDidMount() {
    this.handleGetkeys();
  }

  handleAkey = () => {
    localStorage.setItem('vhsid', '');
    localStorage.setItem('whattodovhs', 'Add Venue');
    this.props.history.push('/venuemng'); //eslint-disable-line
  };



  handleGetkeys = () => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.token,
    };
    var postData = {
      uid: localStorage.usercode,
      table: this.state.tname,
      itemtype: this.state.itemtype,
      item: this.state.item,
    };
    const getuurl = VHS_URL + 'getVitems';
    axios
      .post(getuurl, postData, {
        headers: headers,
      })
      .then((response) => {
        console.log('responce', response.data);
        if (response.status == 400) { }
        if (!response.data.error) {
          localStorage.setItem("token", response.data.token);
          const allitems = [...response.data.allitems];
          console.log('allitems', response.data.allitems);

          this.setState({
            allitems: allitems,
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
        // localStorage.clear();
        //this.props.history.push('/'); //eslint-disable-line
      });
  };

  handleTkey = (id, item, checked) => {
    console.log(checked + 'checked');
    console.log(id + 'id');
    console.log(item + 'item');
    var keysts = checked == true ? 1 : 0;
    var kstatus = 'Deactivate';
    if (checked) {
      kstatus = 'Activate';
    }
    swal({
      title: kstatus + ' ' + item,
      text: 'Are you sure?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((keyconfirm) => {
      if (keyconfirm) {
        const headers = {
          'Content-Type': 'application/json',
          Authorization: localStorage.token,
        };

        const togkeyurl = VHS_URL + 'togitem';
        var postData = {
          keyid: id,
          kstatus: keysts,
          uid: localStorage.usercode,
          table: this.state.tname,

        };
        axios
          .post(togkeyurl, postData, {
            headers: headers,
          })
          .then((response) => {
            console.log(response.data.message);

            if (response.data.error) {
              swal(item + ' ' + response.data.message, {
                icon: 'warning',
              });
            } else {
              localStorage.setItem("token", response.data.token);
              this.handleGetkeys();
              swal(item + ' ' + response.data.message, {
                icon: 'success',
              });
            }
          })
          .catch((error) => {
            console.log('Error is ', error);
            swal('Try Again Later', {
              icon: 'Danger',
            });
          });
      }
    });
  };



  render() {

    const { allitems } = this.state;
    return (
      <>
        <Col md={12} lg={12}>
          <Card>
            <CardBody>

              <div className="card__title">
                <Button
                  color="success" size="sm"
                  className="float-right"
                  onClick={this.handleAkey}
                >
                  +
                </Button>
                <h5 className="bold-text">{this.state.itempageabout}</h5>
                {/* <h5 className="subhead">Use default table with property <span className="red-text">responsive</span></h5> */}

              </div>

            </CardBody>
          </Card>
        </Col>
        {allitems.map(item => (
          <VenueCard key={item.id} item={item} history={this.props.history} />
        ))}

      </>
    );
  }
}


export default withRouter(VenueList)
